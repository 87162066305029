import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {bool, string} from "prop-types";

const button = ({loading, loadingMessage, children, disabled, ...props}) => (
  <Button {...props} disabled={disabled || loading}>
    <div style={{visibility: loading ? "hidden" : undefined}}>
      {children}
    </div>
    {loading &&
    <div style={{position: "absolute", top: "50%", marginTop: -8}}>
      {loadingMessage || <CircularProgress size={20}/>}
    </div>
    }
  </Button>
);

button.propTypes = {
  loading: bool,
  loadingMessage: string,
};

export default button;