import React from 'react';
import NotificationFilters from './NotificationFilters';
import {connect} from "react-redux";
import {setFilterNotificationStatus} from "../../../store/actions/global";


const mapStateToProps = state => ({
  filterStatus: state.global.filters.notificationStatus,
});

const mapDispatchToProps = dispatch => ({
  onSetFilterStatus: status => dispatch(setFilterNotificationStatus(status)),
});

const notificationFiltersContainer = connect(mapStateToProps, mapDispatchToProps)((
  {filterStatus, onSetFilterStatus}
) => (
  <NotificationFilters
    filterStatus={filterStatus}
    onSetFilterStatus={onSetFilterStatus}
  />
));

notificationFiltersContainer.propTypes = {};

export default notificationFiltersContainer;