import React, {useState} from 'react';
import SearchUserDialog from './SearchUserDialog';
import {bool, func, object} from 'prop-types';

const searchUserDialogContainer = ({open, onConfirm, onClose}) => {

  const [selection, setSelection] = useState([]);

  const handleClose = () => {
    setSelection([]);
    onClose();
  };

  return (
    <SearchUserDialog onConfirm={() => onConfirm(selection)} open={open} onClose={handleClose}
                     selection={selection} onChangeUser={setSelection}/>
  );
};

searchUserDialogContainer.propTypes = {
  open: bool,
  selection: object,
  onConfirm: func.isRequired,
  onClose: func,
};

export default searchUserDialogContainer;