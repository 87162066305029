import React from 'react';
import {connect} from "react-redux";
import {isEmpty, some, words} from "lodash";
import {instanceOf, string} from "prop-types";
import moment from "moment";

const mapStateToProps = state => ({
  notifications: state.notification.notifications,
  storeFilterStatus: state.global.filters.notificationStatus,
});

const matchWord = text => word => text.match(RegExp(word, "gi"));

const matchQuery = (text, query) => some(words(query), matchWord(text));

const filterNotifications = (notifications, filterStatus, search, fromDate, toDate) => {
  fromDate = fromDate ? moment(fromDate) : null;
  toDate = toDate ? moment(toDate) : null;

  if (!isEmpty(filterStatus))
    notifications = notifications.filter(notification => filterStatus.includes(notification.status));

  if (fromDate) {
    notifications = notifications.filter(notification => notification.effective_time && moment(notification.effective_time).isSameOrAfter(fromDate))
  }

  if (toDate) {
    notifications = notifications.filter(notification => notification.effective_time && moment(notification.effective_time).isSameOrBefore(toDate))
  }

  if (!isEmpty(search) && search.length >= 2)
    notifications = notifications.filter(notification => matchQuery(notification.content, search));

  return notifications;
};

const filteredNotifications = connect(mapStateToProps)((
  {
    notifications, search, fromDate, toDate, children,
    storeFilterStatus,
  }
) => React.cloneElement(children, {
  notifications: filterNotifications(
    notifications,
    storeFilterStatus,
    search,
    fromDate,
    toDate
  )
}));


filteredNotifications.propTypes = {
  search: string,
  fromDate: instanceOf(Date),
  toDate: instanceOf(Date),
};

export default filteredNotifications;