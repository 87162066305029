import React, {useState} from 'react';
import BottomNavigation from './BottomNavigation';


const bottomNavigationContainer = (() => {
  const [value, setValue] = useState(0);

  return <BottomNavigation value={value} onChangeValue={(e, newValue) => setValue(newValue)}/>;
});


export default bottomNavigationContainer;