import {string, shape, arrayOf, bool} from 'prop-types';

export default shape({
  first_name: string.isRequired,
  last_name: string.isRequired,
  initials: string.isRequired,
  avatar: string,
  email: string.isRequired,
  referral_code: string.isRequired,
  metamorph: bool.isRequired,
  preferences: shape({
    locale: string,
    theme: string,
  }),
  autobio: shape({
    bio: string,
    job_title: string,
    industry: string,
    interests: arrayOf(string),
  }),
});
