import React from 'react';
import {arrayOf, oneOf, string} from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import {isEmpty} from "lodash";
import LinkPreview from "../../../shared/LinkPreview";
import shapes from "../../../../shared/shapes";

const styles = theme => ({
  image: props => ({
    maxWidth: props.size === "small" ? 80 : 200,
    maxHeight: 150,
    display: "block",
    margin: "auto",
    borderRadius: 3,
  }),
  video: props => ({
    maxWidth: props.size === "small" ? 80 : 200,
    maxHeight: 150,
    display: "block",
    margin: "auto",
    borderRadius: 3,
  })
});

const mediaCell = withStyles(styles)(({attachments, link, classes}) => (
  <div>
    {attachments
      .filter(({type}) => type === "image")
      .map(attachment => (
        <img className={classes.image} alt={attachment.id} key={attachment.id}
             src={attachment.url}/>
      ))}
    {attachments
      .filter(({type}) => type === "video")
      .map(attachment => (
        <video controls className={classes.video} key={attachment.id} src={attachment.url}/>
      ))}
    {isEmpty(attachments) && <LinkPreview link={link}/>}
  </div>
));

mediaCell.propTypes = {
  attachments: arrayOf(shapes.attachment),
  link: string,
  size: oneOf(["small", "normal"])
};

export default mediaCell;