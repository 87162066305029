import React from "react";
import {NimblePicker} from "emoji-mart";
import data from 'emoji-mart/data/emojione'
import {bool, func} from "prop-types";
import 'emoji-mart/css/emoji-mart.css'
import './emoji-keyboard.css'
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {noop} from "lodash";

const emojiKeyboard = ({includeRecent, onSelectEmoji, onClose}) => (
  <ClickAwayListener onClickAway={onClose}>
  <NimblePicker
    exclude={includeRecent? undefined : ["recent"]}
    data={data}
    onSelect={emoji => onSelectEmoji(emoji.native)}
    set="emojione"
    sheetSize={16}
    emojiSize={24}
    title='Pick your emoji...' emoji='point_up'
    autoFocus
    native
    showPreview={false}
    showSkinTones={false}
  />
  </ClickAwayListener>
);

emojiKeyboard.propTypes = {
  includeRecent: bool,
  onSelectEmoji: func.isRequired,
  onClose: func,
};

emojiKeyboard.defaultProps = {
  includeRecent: true,
  onClose: noop,
};

export default emojiKeyboard;