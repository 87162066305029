import React from "react";
import CropImage from "../CropImage";
import {bool, func, string} from "prop-types";
import SimpleDialog from "../SimpleDialog";
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import SquareIcon from "@material-ui/icons/CropSquare";

const styles = theme => ({
  image: {
    textAlign: "center"
  },
  controls: {
    textAlign: "center",
  },
  button: {
    margin: theme.spacing(0.5),
  }
});

const cropImageDialog = withStyles(styles)((
  {
    isOpen, onCancel, onSubmit, onCropUpdated, srcImage, square, classes, onRotateLeft,
    onRotateRight, onSetForceSquare, forceSquare,
  }) => (
  <SimpleDialog isOpen={isOpen} onSubmit={onSubmit} onCancel={onCancel}
                title="Crop the image to fit your need">
    {srcImage &&
    <div className={classes.image}>
      <CropImage src={srcImage}
                 keepSelection
                 square={square || forceSquare}
                 onCropUpdated={onCropUpdated}/>
    </div>}
    <div className={classes.controls}>
      <Button variant="outlined" color={forceSquare? "secondary" : undefined}
              className={classes.button} disabled={square} onClick={() => onSetForceSquare(!forceSquare)}>
        <SquareIcon/>
      </Button>
      <Button variant="outlined" className={classes.button} onClick={onRotateLeft}><RotateLeftIcon/></Button>
      <Button variant="outlined" className={classes.button} onClick={onRotateRight}><RotateRightIcon/></Button>
    </div>
  </SimpleDialog>
));

cropImageDialog.propTypes = {
  srcImage: string,
  isOpen: bool.isRequired,
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
  onCropUpdated: func.isRequired,
  square: bool,
  onRotateLeft: func.isRequired,
  onRotateRight: func.isRequired,
  onSetForceSquare: func.isRequired,
  forceSquare: bool,
};

cropImageDialog.defaultProps = {
  square: false,
};

export default cropImageDialog;
