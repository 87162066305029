import React, {useState} from 'react';
import APISearch from './APISearch';
import {any, arrayOf, bool, func, string} from 'prop-types';
import ajax from "../../../shared/util/ajax";
import {debounce, unionBy, without} from "lodash";

const coreRequest = (base, query, optionsGetter) => query.length >= 3 ?
  ajax.get(`${base}${query}`).then(response => optionsGetter(response.data)) :
  new Promise(resolve => resolve([]));

const requestOptions = debounce(coreRequest, 400, {
  trailing: true,
  leading: true
});

const apiSearchContainer = (
  {
    onChangeSelection, selection, baseURL, optionsGetter, multiple, inputLabel, helperText,
    itemRenderer
  }
) => {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState("");

  selection = selection || [];
  const fixedOptions = unionBy(selection, options, "email");

  const handleInputChange = e => {
    const text = e.currentTarget.value;
    setValue(text);
    if (text.length >= 3) {
      const promise = requestOptions(baseURL, text, optionsGetter);
      if (promise)
        promise.then(setOptions);
    }
  };

  const handleItemClick = item => {
    onChangeSelection(
      multiple ?
        (selection.includes(item) ? without(selection, item) : [...selection, item]) :
        (selection.includes(item) ? [] : [item])
    );
  };

  return (
    <APISearch textValue={value} selection={selection} options={fixedOptions}
               helperText={helperText} inputLabel={inputLabel}
               itemRenderer={itemRenderer}
               onClickItem={handleItemClick}
               onInputChange={handleInputChange}/>
  );
};

apiSearchContainer.propTypes = {
  selection: arrayOf(any),
  baseURL: string,
  optionsGetter: func.isRequired,
  multiple: bool,
  onChangeSelection: func.isRequired,
  inputLabel: string,
  helperText: string,
  itemRenderer: func.isRequired,
};

export default apiSearchContainer;