import React from "react";
import Table from "../../shared/Table";
import {arrayOf, func} from "prop-types";
import shapes from "../../../shared/shapes";
import NotificationCell from "./TableCells/NotificationCell";
import MediaCell from "./TableCells/MediaCell";
import StatusCell from "./TableCells/StatusCell";
import BigStatement from "../../shared/BigStatement";
import {isEmpty, orderBy} from "lodash";
import {isWidthDown} from "../../shared/mui";
import MobileNotificationCell from "./TableCells/MobileNotificationCell";


const COMPARERS = {
  'recipients': row => row.recipients.join(', '),
  'notification': row => row.content,
  'mobile-notification': row => row.content,
  'media': row => row.attachments.length + (row.link ? 100 : 0),
  'status': row => row.schedule_time ? row.schedule_time.unix() : 0,
};

const sortNotifications = (rows, orderCol, orderDir) => orderBy(rows, COMPARERS[orderCol], orderDir);


const notificationsTable = (
  {
    notifications, onDeleteRows, onEditRow, onDuplicateRow,
  }
) => {
  const isMobile = isWidthDown("xs");

  const columns = isMobile ? [
    {
      id: 'mobile-notification',
      numeric: false,
      label: 'Notification',
      shortLabel: "Notification"
    },
    {id: 'status', numeric: false, label: 'Status', shortLabel: "Status"},
  ] : [
    {id: 'recipients', numeric: false, label: 'Recipients', shortLabel: "Rec."},
    {
      id: 'notification',
      numeric: false,
      label: 'Notification',
      shortLabel: "Notification"
    },
    {id: 'media', numeric: false, label: 'Media', shortLabel: "Media"},
    {id: 'status', numeric: false, label: 'Status', shortLabel: "Status"},
  ];


  const notificationRenderer = (row, col) => {
    switch (col.id) {
      case 'recipients':
        return row.recipients.join(', ');
      case 'notification':
        return <NotificationCell content={row.content} link={row.link}
                                 title={row.title}
                                 place={row.place ? row.place.name : undefined}/>;
      case 'media':
        return <MediaCell attachments={row.attachments} link={row.link}/>;
      case 'status':
        return <StatusCell status={row.status} notificationId={row.id}
                           scheduleTime={row.schedule_time}/>;
      case 'mobile-notification':
        return <MobileNotificationCell notification={row}/>;
      default:
        return ''
    }
  };

  const preventEdit = rows => (
    isEmpty(rows) ||
    rows.length > 1
  );

  return (
    <Table rows={notifications}
           title="App notifications"
           columns={columns}
           renderer={notificationRenderer}
           sort={sortNotifications}
           onDeleteRows={onDeleteRows}
           onEditRow={onEditRow}
           onDuplicateRow={onDuplicateRow}
           preventEdit={preventEdit}
           noRowContent={<BigStatement title="Nothing here yet..."
                                       color="secondary"
                                       subtitle="Why not create a notification now?"
                                       size="large"/>}/>
  );
};

notificationsTable.propTypes = {
  notifications: arrayOf(shapes.notification),
  onDeleteRows: func.isRequired,
  onEditRow: func.isRequired,
};

export default notificationsTable;