/**
 * Wrapper around a reducer to make it spread the previous state automatically.
 * In addition, if there is no update from the actual reducer, this wrapper
 * will take a shortcut and return the original one.
 * @param initialState
 * @returns {function(*): function(*=, *=): *}
 */
export const withSpreadState = (initialState={}) => reducer => (
  (state = initialState, action) => {
    const updates = reducer(state, action);
    return updates ? {...state, ...updates} : state;
  }
);

/**
 * Creates a reducer out of a mapping actions/handlers.
 * @param actionsMap
 * @returns {function(*, {type: string}): *} A Reducer
 */
export const makeReducer = actionsMap => (
  (state, action) => {
    if (actionsMap.hasOwnProperty(action.type)) {
      return actionsMap[action.type](action, state);
    }
    return state;
  }
);
