import React from "react";
import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';
import {string, func, number, bool} from "prop-types";
import shapes from "../../../shared/shapes";


const cropImage = ({
    src, onChange, crop, minWidth, minHeight, maxWidth,
    maxHeight, keepSelection, locked, onComplete, onImageLoaded
  }) => (
  <ReactCrop
    src={src}
    onChange={onChange}
    onComplete={onComplete}
    crop={crop}
    minWidth={minWidth}
    minHeight={minHeight}
    maxWidth={maxWidth}
    maxHeight={maxHeight}
    keepSelection={keepSelection}
    locked={locked}
    imageStyle={{maxHeight: "60vh"}}
    onImageLoaded={onImageLoaded}/>
);

cropImage.propTypes = {
  src: string.isRequired,
  onChange: func.isRequired,
  onComplete: func.isRequired,
  onImageLoaded: func.isRequired,
  crop: shapes.crop.isRequired,
  minWidth: number,
  minHeight: number,
  maxWidth: number,
  maxHeight: number,
  keepSelection: bool,
  locked: bool,
};

export default cropImage;
