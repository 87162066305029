import React from 'react';
import {bool, func, object} from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import SimpleDialog from "../../../shared/SimpleDialog";
import Typography from "@material-ui/core/Typography";
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';

const styles = () => ({});


const scheduleDialog = withStyles(styles)((
  {
    open, onSubmit, onCancel, selectedDate, onDateChange,
  }
) => {

  return (
    <SimpleDialog isOpen={open} title="Schedule post"
                  onSubmit={onSubmit} onCancel={onCancel}>
      <Typography variant="h6">Date & Time</Typography>
      <Typography variant="subtitle2" paragraph>
        Please select the date and time you want your post to be published.
      </Typography>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker margin="normal" label="Date picker"
                            placeholder="24/08/2019" format="dd/MM/yyyy"
                            minDate={new Date()}
                            value={selectedDate} onChange={onDateChange}/>
        <KeyboardTimePicker margin="normal" label="Time picker"
                            value={selectedDate} onChange={onDateChange}/>
      </MuiPickersUtilsProvider>
    </SimpleDialog>
  );
});

scheduleDialog.propTypes = {
  open: bool.isRequired,
  onSubmit: func.isRequired,
  onCancel: func.isRequired,
  selectedDate: object,
  onDateChange: func.isRequired,
};

export default scheduleDialog;