import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import logo from "../../../shared/images/logo-trans-512.png";
import classNames from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Typography} from "@material-ui/core";
import {string} from "prop-types";


const styles = theme => ({
  root: {
    position: "fixed",
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
    textAlign: "center",
    border: `solid 1px ${theme.palette.divider}`,
  },
  logo: {
    width: 100,
  },
  progress: {
    marginTop: theme.spacing(2),
  },
});

const Splash = withStyles(styles)(({message, classes}) => (
  <div className={classes.root}>
    <img src={logo} className={classNames(classes.logo)} alt="Logo"/>
    <Typography component="h1" variant="h4" align="center" color="primary">bobcaat admin</Typography>
    <Typography component="h2" variant="h5" align="center">We are loading the app.</Typography>
    <Typography component="h3" variant="h6" align="center"
                color="textSecondary">{message}</Typography>
    <CircularProgress className={classes.progress} size={150}/>
  </div>
));

Splash.propTypes = {
  message: string.isRequired,
};

export default Splash;
