import {PROFILE_LOAD_USER} from "../actions/actionTypes";
import {makeReducer, withSpreadState} from "./common";
import {setInfo} from "../actions/global";

const initialState = {
  currentUser: null
};

const loadUser = action => {
  const user = {
    ...action.val,
    initials: (action.val.first_name[0] + action.val.last_name[0]).toUpperCase(),
    full_name: () => `${action.val.first_name} ${action.val.last_name}`,
  };

  setInfo(`Nice to see you again, ${user.firstName}`);
  return {currentUser: user}
};

export default withSpreadState(initialState)(makeReducer({
  [PROFILE_LOAD_USER]: loadUser
}));