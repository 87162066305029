const drawRotated = (image, direction) => {
  if (typeof image === "string") {
    const dataURL = image;
    image = document.createElement("img");
    image.src = dataURL;
  }
  const canvas = document.createElement('canvas');
  canvas.width = image.height;
  canvas.height = image.width;

  const context = canvas.getContext('2d');

  // move to the center of the canvas
  context.translate(canvas.width / 2, canvas.height / 2);

  // rotate the canvas to the specified degrees
  const degrees = direction === "left" ? -90 : 90;
  context.rotate(degrees * Math.PI / 180);

  // draw the image
  // since the context is rotated, the image will be rotated also
  context.drawImage(image, -image.width / 2, -image.height / 2);

  return canvas.toDataURL('image/jpeg');
};

/**
 * @param {HTMLImageElement} image Image File Object
 * @param {*} crop crop Object
 */
export const cropImage = (image, crop) => {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );

  return canvas.toDataURL('image/jpeg');
};

/**
 * Rotates provided image to the left
 * @param {File|string|Blob} image Can be a file, a data URL or a blob.
 * @return {Promise<string>} The src of the fixed image.
 */
export const rotateLeft = image => drawRotated(image, "left");

/**
 * Rotates provided image to the right
 * @param {File|string|Blob} image Can be a file, a data URL or a blob.
 * @return {Promise<string>} The src of the fixed image.
 */
export const rotateRight = image => drawRotated(image, "right");

/**
 * Obtains dimensions of an image file.
 * @param {File} file the file to check.
 * @return {Promise<{number, number}>} A Promise resolved with an object containing width and height.
 */
export const getImageSize = file => new Promise((resolve, reject) => {
  const img = new Image();
  img.src = URL.createObjectURL(file);
  img.onload = () => {
    const width = img.naturalWidth;
    const height = img.naturalHeight;
    window.URL.revokeObjectURL(img.src);
    console.log("Image size: ", width, "x", height);
    resolve({width, height});
  };
  img.onerror = reject;
});