import React from "react";
import Drawer from "@material-ui/core/Drawer";
import classNames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {withStyles} from "@material-ui/core/styles";
import {bool, func} from "prop-types";
import {isWidthDown} from "../../shared/mui";
import {noop} from "lodash";
import NavLink from "../../shared/NavLink";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import appSections from "../../../shared/refer/appSections";


const styles = theme => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 4px',
    ...theme.mixins.toolbar,
  },
  drawer: {
    position: 'absolute',
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.primary.main,
    width: theme.metrics.drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {width: theme.spacing(8)},
  },
  chevronIcon: {
    color: theme.palette.primary.contrastText,
  },
  link: {
    color: theme.palette.primary.contrastText,
  },
  linkIcon: {
    color: theme.palette.primary.contrastText,
  },
  activeLink: {
    borderLeftWidth: theme.spacing(0.5),
    paddingLeft: theme.spacing(1.5),
    borderColor: theme.palette.secondary.main,
    borderLeftStyle: "solid",
  },
});

const drawer = withStyles(styles)((
  {isOpen, onClose, onOpen, classes}
) => (
  <>
    <Drawer
      variant="permanent" onClick={isOpen && isWidthDown('sm') ? onClose : noop}
      classes={{paper: classNames(classes.drawer, !isOpen && classes.drawerClose)}} open={isOpen}>
      <div className={classes.toolbarIcon}>
        <IconButton className={classes.chevronIcon} onClick={isOpen ? onClose : onOpen}>
          {isOpen ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
        </IconButton>
      </div>
      <Divider/>
      <List component="nav">
        {appSections.map(item => (
          <ListItem disabled={item.disabled} button component={NavLink} to={item.to}
                    className={classes.link}
                    exact={item.exact} key={item.label} activeClassName={classes.activeLink}>
            <ListItemIcon className={classes.linkIcon}>
              <item.Icon/>
            </ListItemIcon>
            <ListItemText
              primary={item.label}
              primaryTypographyProps={{color: "inherit"}}
            />
          </ListItem>
        ))}
        <Divider/>
      </List>
    </Drawer>
  </>
));

drawer.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onOpen: func.isRequired,
};

export default drawer;