import React from 'react';
import {shape, string} from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import NoLinkIcon from "@material-ui/icons/LinkOff";
import NoPreviewIcon from "@material-ui/icons/Error";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";


const styles = theme => ({
  nolink: {
    color: theme.palette.grey[500],
    margin: "auto",
    display: "block",
  },
  root: {
    border: "solid 1px #C0C0C0",
    display: "inline-block",
    "& img": {
      width: 150,
      margin: "auto",
      display: "block",
    },
  },
  title: {
    padding: theme.spacing(0.5),
    display: "block",
    width: 150,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",

  }
});

const linkPreview = withStyles(styles)(({link, preview, classes}) => {

  if (!link) {
    return <NoLinkIcon className={classes.nolink} titleAccess="No attachment"/>
  }

  if (!preview) {
    return <LinearProgress title="Loading..."/>;
  }


  if (!preview.image) {
    return (
      <div className={classes.root}>
      <NoPreviewIcon className={classes.nolink} style={{height: 50}} titleAccess="Unable to preview this link."/>
      <Typography variant="caption" className={classes.title} align="center">Unable to preview</Typography>
    </div>
    );
  }

  return (
    <div className={classes.root}>
      <img src={preview.image} alt={preview.title}/>
      <Typography variant="caption" className={classes.title}>{preview.title}</Typography>
    </div>
  );
});

linkPreview.propTypes = {
  link: string,
  preview: shape({
    title: string,
    description: string,
    image: string,
  })
};


export default linkPreview;