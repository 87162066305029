import React, {useEffect, useState} from 'react';
import LinkPreview from './LinkPreview';
import {string} from 'prop-types';
import ajax from "../../../shared/util/ajax";

const requestPreview = async link => {
  const preview = await ajax.get("misc/preview/" + encodeURIComponent(encodeURIComponent(link)));
  return preview ? preview.data : null;
};


const LinkPreviewContainer = ({link}) => {

  const [preview, setPreview] = useState(null);

  const refreshPreview = async () => {
    if (!link) {
      setPreview(null);
    } else {
      setPreview(await requestPreview(link));
    }
  };

  useEffect(() => {refreshPreview()}, [link]);

  return (
    <LinkPreview link={link} preview={preview}/>
  );
};

LinkPreviewContainer.propTypes = {
  link: string,
};

export default LinkPreviewContainer;