import React from "react";
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {arrayOf, bool, func, number, string} from "prop-types";
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from "@material-ui/core/Tooltip";
import shapes from "../../../../shared/shapes";
import {isWidthDown} from "../../mui";

const tableHead = (
  {
    onSelectAllClick, order, orderBy, numSelected, rowCount, columns, onRequestSort, selectable
  }
) => {
  const isMobile = isWidthDown("xs");

  return (
    <TableHead>
      <TableRow>
        {selectable &&
        <TableCell padding="checkbox">
          <Checkbox indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={numSelected === rowCount} onChange={onSelectAllClick}/>
        </TableCell>}
        {columns.map(col => (
          <TableCell
            padding="default" key={col.id} align={col.numeric ? 'right' : 'left'}
            style={{minWidth: col.minWidth}}
            sortDirection={orderBy === col.id ? order : false}>
            <Tooltip title="Sort" enterDelay={300}
                     placement={col.numeric ? 'bottom-end' : 'bottom-start'}>
              <TableSortLabel active={orderBy === col.id} direction={order}
                              onClick={e => onRequestSort(e, col.id)} href="">
                {isMobile ? col.shortLabel : col.label}
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

tableHead.propTypes = {
  numSelected: number.isRequired,
  onRequestSort: func.isRequired,
  onSelectAllClick: func.isRequired,
  order: string.isRequired,
  orderBy: string.isRequired,
  rowCount: number.isRequired,
  columns: arrayOf(shapes.tableHeadColumn).isRequired,
  selectable: bool.isRequired,
};

export default tableHead;
