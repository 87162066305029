import {
  NOTIFICATION_ADD,
  NOTIFICATION_DELETE,
  NOTIFICATION_EDIT,
  NOTIFICATION_LOAD_ALL,
  NOTIFICATION_SET_STATE,
} from "../actions/actionTypes";
import {makeReducer, withSpreadState} from "./common";
import moment from "moment";
import {splice} from "../../shared/util/lodashex";

const initialState = {
  notifications: [],
  wizard: {
    notificationId: null,
  }
};

const transformNotification = notification => {
  let schedule_time = notification.schedule_time ? moment.unix(notification.schedule_time) : null;
  return {...notification, schedule_time};
};

const addNotification = (action, oldState) => {
  const notification = transformNotification(action.val);

  return {
    notifications: [notification, ...oldState.notifications],
  };
};

const editNotification = (action, oldState) => ({
  notifications: splice(
    oldState.notifications,
    oldState.notifications.findIndex(notification => notification.id === action.val.id),
    1,
    transformNotification(action.val)
  )
});

const deleteNotification = (action, oldState) => ({
  notifications: oldState.notifications.filter(notification => notification.id !== action.val),
});


const loadAllNotifications = action => {
  const notifications = action.val.map(transformNotification);

  return ({notifications});
};

const setNotificationWizardState = (action, oldState) => ({
  wizard: {
    ...oldState.wizard,
    ...action.val,
  }
});


export default withSpreadState(initialState)(makeReducer({
  [NOTIFICATION_ADD]: addNotification,
  [NOTIFICATION_EDIT]: editNotification,
  [NOTIFICATION_DELETE]: deleteNotification,
  [NOTIFICATION_LOAD_ALL]: loadAllNotifications,
  [NOTIFICATION_SET_STATE]: setNotificationWizardState,
}));
