import React from "react";
import WithInputLimits from "../../../../shared/hoc/WithInputLimits";
import TextField from "@material-ui/core/TextField";
import {withStyles} from "@material-ui/core";
import {func, string} from "prop-types";


const styles = theme => ({
  textfield: {
    flexGrow: 1,
    height: "100%",
  },
  textfieldInput: {
    flexGrow: 1,
    height: "100%",
    "& textarea": {
      height: "100%",
      padding: theme.spacing(0.5)
    }
  },
  textfieldFormHelper: {
    backgroundColor: theme.shades.darker[5],
    marginTop: 0,
    padding: theme.spacing(1),
  },
});

const messageBox = withStyles(styles)(React.forwardRef(
  ({content, onChangeContent, classes}, ref) => (
    <WithInputLimits maxLength={5000} value={content}
                     onChange={onChangeContent}>
      <TextField
        className={classes.textfield}
        FormHelperTextProps={{className: classes.textfieldFormHelper}}
        InputProps={{className: classes.textfieldInput}}
        inputRef={ref}
        placeholder="What is up today?"
        multiline rows="16"
      />
    </WithInputLimits>
  )));

messageBox.propTypes = {
  content: string.isRequired,
  onChangeContent: func.isRequired,
};


export default messageBox;