import React from "react";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import NavLink from "../../shared/NavLink";
import {withStyles} from "@material-ui/core";
import {number, func} from "prop-types";
import appSections from "../../../shared/refer/appSections";

const styles = theme => ({
  root: {
    borderTop: `solid 1px ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    position: "fixed",
    bottom:0,
    left:0,
    width: "100vw",
    zIndex:1000,
  }
});

const bottomNavigation = withStyles(styles)(({value, onChangeValue, classes}) =>
  <BottomNavigation value={value} onChange={onChangeValue} className={classes.root}>
    {appSections.map(item => (
      <BottomNavigationAction key={item.label} label={item.label} icon={<item.Icon/>}
                              component={NavLink} to={item.to} exact={item.exact}/>
    ))}
  </BottomNavigation>
);

bottomNavigation.propTypes = {
  value: number.isRequired,
  onChangeValue: func.isRequired,
};

export default bottomNavigation;