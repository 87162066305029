const EMAIL_REGEX = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i;
const URL_REGEX = /^(http:\/\/|https:\/\/)?([-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*))*$/i;

const notEmpty = () => (name, value) => (
  value.length > 0 ?
    "" : `${name} cannot be empty`
);

const match = pattern => (name, value) => (
  value.match(pattern) ?
    "" : `${name} does not look valid`
);

const atMaxLength = max => (name, value) => (
  value.length <= max ?
    "" :
    `${name} should be ${max} characters long at max`
);

/**
 *
 * @param tests {function}
 * @returns {*}
 */
const compose = (...tests) => (name, value) => (
  tests
    .map(test => test(name, value)) // Do all tests.
    .filter(res => Boolean(res))    // filter no errors.
);

export const checkEmail = compose(
  notEmpty(),
  match(EMAIL_REGEX)
);

export const checkPassword = compose(
  notEmpty(),
);

export const checkURL = compose(
  match(URL_REGEX)
);

export const checkPortfolioName = compose(
  notEmpty(),
  atMaxLength(50),
);

export const checkBugTitle = compose(
  notEmpty(),
  atMaxLength(100),
);

export const checkBugDescription = compose(
  notEmpty(),
  atMaxLength(2048),
);

export const checkInviteFriendName = compose(
  notEmpty(),
  atMaxLength(100),
);

export const checkInviteFriendEmail = compose(
  notEmpty(),
  atMaxLength(100),
  match(EMAIL_REGEX),
);

export const checkInviteFriendMessage = compose(
  atMaxLength(2048),
);
