import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "../../shared/Select";
import suggestions from "../../../shared/suggestions";
import {isWidthDown} from "../../shared/mui";
import {func, string} from "prop-types";

const styles = theme => ({
  root: {},
  filterButton: {
    minWidth: 150,
    [theme.breakpoints.up('sm')]: {marginLeft: theme.spacing(0.5)},
    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
      marginRight: theme.spacing(0.5)
    },
  },
});

const notificationFilters = withStyles(styles)((
  {
    filterStatus, onSetFilterStatus, classes
  }) => {
  const isMobile = isWidthDown("xs");
  const controlsMargin = isMobile ? "dense" : "normal";
  return (
    <div>
      <Select onNewValue={onSetFilterStatus} margin={controlsMargin}
              className={classes.filterButton} emptyOption="All"
              id="notification-status" variant="outlined" fullWidth={isMobile}
              label="Status"
              options={suggestions.notificationStatus} value={filterStatus}/>
    </div>
  );
});

notificationFilters.propTypes = {
  filterStatus: string,
  onSetFilterStatus: func.isRequired,
};

export default notificationFilters;