import React from "react";
import classNames from "classnames";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import BugIcon from '@material-ui/icons/BugReport';
import MoreIcon from '@material-ui/icons/MoreVert';
import ContactIcon from '@material-ui/icons/ContactSupport';
import LogOutIcon from '@material-ui/icons/ExitToApp';
import {bool, func} from 'prop-types';
import {withStyles} from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import logo from "../../../shared/images/logo-trans-512-white.png";
import Hidden from "@material-ui/core/Hidden";
import {flow} from "lodash";
import ReportBugDialog from "./ReportBugDialog";
import {isWidthDown} from "../../shared/mui";
import ConfirmDialog from "../../shared/ConfirmDialog";

const styles = theme => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    marginLeft: theme.metrics.collapsedDrawerWidth,
    width: `calc(100% - ${theme.metrics.collapsedDrawerWidth}px)`,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      width: `calc(100%)`,
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.metrics.drawerWidth,
      width: `calc(100% - ${theme.metrics.drawerWidth}px)`,
    }
  },
  logo: {
    width: "48px",
    [theme.breakpoints.down('xs')]: {width: "45px"},
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  title: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1,
  },
});


const appBar = withStyles(styles)(
  ({
     isShifted, mobileMoreAnchorEl, onOpenMobileMenu, onCloseMobileMenu, onCloseReportABug,
     onOpenReportABug, bugReportDialogOpen, title, onRequestLogOut, onCancelLogOut, isConfirmLogOutOpen,
     onConfirmLogOut, classes,
   }) => {
    const appBarCls = classNames(classes.appBar, isShifted && classes.appBarShift);

    const desktopMenuItems = [
      {
        label: "Contact support",
        Icon: ContactIcon,
        click: () => window.open(
          process.env.REACT_APP_LANDING_URL + "/contact?t=" + localStorage.appToken
        ),
      },
      {label: "Report a bug", Icon: BugIcon, click: onOpenReportABug},
      {label: "Log out", Icon: LogOutIcon, click: onRequestLogOut},
    ];
    const mobileMenuItems = [
      ...desktopMenuItems,
    ];
    const isMobile = isWidthDown("xs");
    const shownTitle = isMobile ? title : "bobcaat admin";

    return (
      <>
        {isConfirmLogOutOpen && <ConfirmDialog
          onConfirm={onConfirmLogOut}
          title="Confirm log out?"
          onCancel={onCancelLogOut}>
          You will be redirected to bobcaat's landing page.
        </ConfirmDialog>}
        <ReportBugDialog onClose={onCloseReportABug}
                         open={bugReportDialogOpen}/>
        <AppBar position="absolute" className={appBarCls} elevation={0}>
          <Toolbar className={classes.toolbar}>
            <img src={logo} className={classNames(classes.logo)} alt="Logo"/>
            <Typography component="h1" variant="h6" color="inherit" noWrap
                        align={isMobile ? "center" : undefined}
                        className={classes.title}>
              {shownTitle}
            </Typography>
            <div className={classes.grow}/>

            {/*Desktop menu*/}
            <Hidden xsDown>
              {desktopMenuItems
                .filter(({hidden}) => !hidden)
                .map(({Icon, label, click}) => (
                  <IconButton color="inherit" key={label} title={label}
                              onClick={click}>
                    <Icon/>
                  </IconButton>
                ))}
            </Hidden>
            <Hidden smUp>
              <IconButton aria-haspopup="true" color="inherit"
                          onClick={onOpenMobileMenu}>
                <MoreIcon/>
              </IconButton>
            </Hidden>
          </Toolbar>

          {/* Mobile menu */}
          <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={Boolean(mobileMoreAnchorEl)}
            onClose={onCloseMobileMenu}
          >
            {mobileMenuItems
              .filter(({hidden}) => !hidden)
              .map(({Icon, click, label}) => (
                <MenuItem title={label} key={label}
                          onClick={flow(click, onCloseMobileMenu)}>
                  <IconButton color="inherit"><Icon/></IconButton>
                  <p>{label}</p>
                </MenuItem>
              ))}
          </Menu>
        </AppBar>
      </>
    );
  });

appBar.propTypes = {
  isShifted: bool.isRequired,
  bugReportDialogOpen: bool.isRequired,
  onOpenReportABug: func.isRequired,
  onCloseReportABug: func.isRequired,
  onRequestLogOut: func.isRequired,
  onCancelLogOut: func.isRequired,
  isConfirmLogOutOpen: bool.isRequired,
  onConfirmLogOut: func.isRequired,
};

appBar.defaultProps = {};

export default appBar;