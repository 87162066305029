import {identity} from "lodash";
import numeral from "numeral";
import {getImageSize} from "./images";

export const KBYTE = 1024;
export const MBYTE = 1024 * KBYTE;
export const GBYTE = 1024 * MBYTE;

/**
 * Gets an uploaded file content.
 * @param file {File} The uploaded file.
 * @return {Promise} called with the resulting data
 */
export const getFileAsBlob = file => new Promise(resolve => {
  resolve(URL.createObjectURL(file));
});

export const getFileAsDataURL = file => new Promise(resolve => {
  const reader = new FileReader();
  reader.onload = e => resolve(e.currentTarget.result);
  reader.readAsDataURL(file);
});

const withCheckFile = (maxSize, fileType, maxWidth, maxHeight, onError, onSuccess = identity) => async file => {
  if (!file) {
    return;
  }
  if (file.size > maxSize) {
    onError(`File is too big (${prettySize(file.size)}). Maximum size is ${prettySize(maxSize)}.`);
    return;
  }
  if (!file.type.startsWith(fileType)) {
    onError(`File does not match expected types, sorry.`);
    return;
  }

  if (file.type.includes("image")) {
    const {width, height} = await getImageSize(file);
    if (width > maxWidth || height > maxHeight) {
      onError(`File dimensions (${width}x${height}) exceeds max size (${maxWidth}x${maxHeight}).`);
      return;
    }
  }

  return await onSuccess(file);
};

export const getBlob = (maxSize, fileType, onError) => async file => (
  await withCheckFile(maxSize, fileType, 2048, 2048, onError, getFileAsBlob)(file)
);

export const getDataURL = (maxSize, fileType, onError) => async file => (
  await withCheckFile(maxSize, fileType, 2048, 2048, onError, getFileAsDataURL)(file)
);

export const getFile = (maxSize, fileType, onError) => async file => (
  await withCheckFile(maxSize, fileType, 2048, 2048, onError)(file)
);

/**
 * Display a byte size in a pretty way.
 * @param size {Number} The size in bytes.
 */
export const prettySize = size => numeral(size).format('0.00b');
