import React from 'react';
import {string} from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import TextPreview from "../../../shared/TextPreview";

const styles = theme => ({
  root: {
    display: 'inline-block',
  },
  caption: {
    color: theme.palette.primary.main,
  }
});

const notificationCell = withStyles(styles)(({title, content, link, classes}) => (
  <div className={classes.root}>
    {title && <Typography variant="caption" color="textSecondary">{title}</Typography>}
    <TextPreview variant="body2">{content}</TextPreview>
    {link && <Link href={"http://" + link} target="_blank">{link}</Link>}
  </div>
));

notificationCell.propTypes = {
  title: string,
  content: string,
  link: string,
};

export default notificationCell;