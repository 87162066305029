import React from "react";
import {Typography, withStyles} from "@material-ui/core";
import {any} from "prop-types";

const styles = theme => ({
  root: {
    width: "95%",
    maxWidth: "1000px",
    height: `100%`,
    margin: "auto",
    position: "relative",
    overflow:"auto",
    padding: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {width: "98%"},
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      padding: 0,
      height: "initial",
      paddingTop: theme.spacing(2),
    }
  },
  title: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    fontWeight: "bold",
    [theme.breakpoints.up('sm')]: {marginLeft: 0},
     [theme.breakpoints.down('xs')]: {display: "none"}
  }
});

const sectionHolder = withStyles(styles)(({title, children, classes}) => (
  <div className={classes.root}>
    <Typography component="h2" variant="h4" className={classes.title} color="secondary" gutterBottom>
      {title}
    </Typography>
    {children}
  </div>
));


sectionHolder.propTypes = {
  title: any,
};

export default sectionHolder;