import React from 'react';
import DashboardSection from './DashboardSection';
import {connect} from "react-redux";


const mapStateToProps = state => ({
  user: state.profile.currentUser,
});


const DashboardSectionContainer = connect(mapStateToProps)(({user}) => {
  return (
    <DashboardSection user={user}/>
  );
});

export default DashboardSectionContainer;