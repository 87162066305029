import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import SimpleDialog from "../../../shared/SimpleDialog";
import BugIcon from "@material-ui/icons/BugReport";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import WithInputLimits from "../../../shared/hoc/WithInputLimits";
import {arrayOf, bool, func, string} from "prop-types";
import Button from "@material-ui/core/Button";
import ImagePicker from "../../../shared/hoc/ImagePicker";
import ImageList from "../../../shared/ImageList/ImageList";
import ImageIcon from '@material-ui/icons/Image';

const styles = theme => ({
  attachImage: {
    marginTop: theme.spacing(2),
  },
  imageList: {
    marginTop: theme.spacing(2),
  }
});

const reportBugDialog = withStyles(styles)((
  {
    open, title, description, images, onClose, onSubmit, onChangeTitle, onChangeDescription,
    onAttachImage, onDetachImage, titleError, descriptionError, classes, onReorderImages
  }
) => (
  <SimpleDialog isOpen={open} onCancel={onClose} onSubmit={onSubmit}
                cancelLabel="Cancel" submitLabel="Report now" maxWidth="sm"
                title={<><BugIcon/> Report a bug</>} onClose={onClose}>
    <Typography gutterBottom>
      We are sorry to hear you are having issues with bobcaat. Please report it to us and we will
      fix it as soon as possible! Alternatively you can contact us via email or on any social
      network.
    </Typography>
    <Typography paragraph>
      You will be notified as soon as the issue is resolved.
    </Typography>
    <WithInputLimits maxLength={100} onChange={onChangeTitle} value={title}
                     error={!!titleError} helperText={titleError}>
      <TextField label="Title" placeholder="Give a short title to sum up the issue."
                 fullWidth margin="normal" required value={title} onChange={onChangeTitle}/>
    </WithInputLimits>
    <WithInputLimits maxLength={2048} onChange={onChangeDescription} value={description}
                     error={!!descriptionError} helperText={descriptionError}>
      <TextField label="Description" multiline
                 placeholder="Please give as much detail as possible, explaining the steps that led
               you to the issue if it is important."
                 fullWidth margin="normal" required/>
    </WithInputLimits>
    <div className={classes.attachImage}>
      <Typography gutterBottom>You can attach up to 2 screenshots:</Typography>
      <ImagePicker onUpload={onAttachImage}>
        <Button variant="contained" disabled={images.length >= 2}><ImageIcon/> Pick image</Button>
      </ImagePicker>
      <ImageList className={classes.imageList} images={images} onImageClick={onDetachImage} onReorderImages={onReorderImages}/>
    </div>
  </SimpleDialog>
));

reportBugDialog.propTypes = {
  open: bool.isRequired,
  title: string.isRequired,
  description: string.isRequired,
  images: arrayOf(string).isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  onChangeTitle: func.isRequired,
  onChangeDescription: func.isRequired,
  onAttachImage: func.isRequired,
  onDetachImage: func.isRequired,
  descriptionError: string,
  titleError: string,
  onReorderImages: func.isRequired,
};

export default reportBugDialog;