import AppBar from './AppBar';
import {connect} from "react-redux";
import React, {useState} from "react";
import {bool, string} from "prop-types";


const mapStateToProps = state => {
  return {
    user: state.profile.currentUser,
  };
};


const appBarContainer = connect(mapStateToProps)((
  {isShifted, user, title}
) => {

  const [mobileMoreAnchor, setMobileMenuAnchor] = useState(null);
  const [bugReportDialogOpen, setBugReportDialogOpen] = useState(false);
  const [isConfirmLogOutOpen, setConfirmLogOutDialogOpen] = useState(false);

  const handleConfirmLogout = () => {
    window.localStorage.removeItem("appToken");
    window.location.href = "/login";
  };

  return <AppBar
    isShifted={isShifted}
    title={title}
    user={user}
    mobileMoreAnchorEl={mobileMoreAnchor}
    onOpenMobileMenu={e => setMobileMenuAnchor(e.currentTarget)}
    onCloseMobileMenu={() => setMobileMenuAnchor(null)}

    bugReportDialogOpen={bugReportDialogOpen}
    onOpenReportABug={() => setBugReportDialogOpen(true)}
    onCloseReportABug={() => setBugReportDialogOpen(false)}

    onRequestLogOut={() => setConfirmLogOutDialogOpen(true)}
    onCancelLogOut={() => setConfirmLogOutDialogOpen(false)}
    isConfirmLogOutOpen={isConfirmLogOutOpen}
    onConfirmLogOut={handleConfirmLogout}
  />
});

appBarContainer.propTypes = {
  isShifted: bool,
  title: string,
};

export default appBarContainer;
