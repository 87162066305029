import {Button, makeStyles, Tooltip} from "@material-ui/core";
import React from "react";
import {object, oneOf, string} from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {margin: theme.spacing(0.5)},
}));

const button = React.forwardRef(({color, Icon, children, title, ...props}, ref) => (
  <Tooltip title={props.disabled ? "" : title}>
    <Button {...props} ref={ref} className={useStyles().root} variant="contained" color={color}>
      {Icon && <Icon/>}{children}
    </Button>
  </Tooltip>
));

button.propTypes = {
  color: oneOf(["primary", "secondary", "error", "default"]),
  Icon: object,
  title: string,
};

button.defaultProps = {
  color: "primary",
  title: "",
};

export default button;