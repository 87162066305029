import {isEmpty} from "lodash";

const REGEX_URL = new RegExp(
  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?/gi
);
const WHITESPACE = /^\s$/.compile();

export const findURL = text => {
  const match = text.match(REGEX_URL);
  return match && !isEmpty(match[0])? match[0] : null;
};

export const isWhitespace = text => WHITESPACE.test(text);
