import React, {useEffect, useState} from "react";
import NotificationWizard from "./NotificationWizard";
import {sum, without} from "lodash";
import {
  addNotificationAsync,
  editNotificationAsync, setNotificationWizardState,
} from "../../../store/actions/notification";
import {setStringError} from "../../../store/actions/global";
import {connect} from "react-redux";
import ScheduleDialog from "./ScheduleDialog";
import {
  DRAFT,
  PUBLISHED,
  SCHEDULED
} from "../../../shared/refer/notificationStatus";
import moment from "moment";
import SearchUserDialog from "./SearchUserDialog";


const mapDispatchToProps = dispatch => ({
  onAddNotification: notification => dispatch(addNotificationAsync(notification)),
  onEditNotification: notification => dispatch(editNotificationAsync(notification)),
  onSetStringError: msg => dispatch(setStringError(msg)),
  onStopEdit: () => dispatch(setNotificationWizardState(null)),
});

const mapStateToProps = state => ({
  notifications: state.notification.notifications,
  notificationId: state.notification.wizard.notificationId,
});

const notificationWizardContainer = connect(mapStateToProps, mapDispatchToProps)((
  {
    onAddNotification, notifications, notificationId, onEditNotification, onStopEdit
  }
) => {

  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [isScheduling, setIsScheduling] = useState(false);
  const [uploadSize, setUploadSize] = useState(0);
  const [recipients, setRecipients] = useState(["all"]);
  const [isAddingRecipients, setIsAddingRecipients] = useState(false);

  useEffect(() => {
    const notification = notifications.find(notification => notification.id === notificationId);
    if (notification) {
      setContent(notification.content);
      setImages(notification.attachments.filter(a => a.type === "image").map(a => a.url));
      setVideos(notification.attachments.filter(a => a.type === "video").map(a => a.url));
      setTitle(notification.title);
      setRecipients(notification.recipients);
    }
  }, [notificationId]);

  useEffect(() => {
    if (notificationId) {
      if (!notifications.find(notification => notification.id === notificationId)) {
        // notification has been deleted.
        handleReset();
      }
    }
  }, [notifications]);


  const editedNotification = notifications.find(notification => notification.id === notificationId);
  const isEditingPublishedNotification = !!editedNotification && editedNotification.status === PUBLISHED.slug;

  const handleReset = () => {
    setContent("");
    setTitle("");
    setImages([]);
    setVideos([]);
    setIsScheduling(false);
    setUploadSize(0);
    setRecipients(["all"]);
    onStopEdit();
  };

  const handleNotificationAnyState = (status, time = null) => {
    if (!notificationId) {
      onAddNotification({
        title,
        content,
        status,
        images,
        videos,
        time,
        recipients,
      })
    } else {
      onEditNotification({
        id: notificationId,
        title,
        content,
        status,
        images,
        videos,
        time,
        recipients,
      })
    }
  };

  const handleStartSchedule = () => setIsScheduling(true);

  const handleSchedule = (datetime, timezone) => handleNotificationAnyState(
    SCHEDULED.slug,
    datetime.unix(),
    timezone
  );

  const handlePostNow = () => {
    handleNotificationAnyState(PUBLISHED.slug, moment().unix());
  };

  const handleSaveDraft = () => handleNotificationAnyState(DRAFT.slug);


  const handleAttachImage = newImages => {
    const concatImages = [...images, ...newImages];
    setImages(concatImages);
    setUploadSize(uploadSize + sum(newImages.map(image => image.length)));
  };

  const handleDetachImage = image => {
    setImages(without(images, image));
    if (image.startsWith('data:')) {
      // we do not care for previously updloaded video. Just files to upload.
      setUploadSize(uploadSize - image.length);
    }
  };

  const handleDeleteRecipient = email => {
    setRecipients(without(recipients, email));
  };

  return (
    <>
      <ScheduleDialog open={isScheduling} onSubmit={handleSchedule}
                      onCancel={() => setIsScheduling(false)}/>
      <SearchUserDialog open={isAddingRecipients}
                        onConfirm={users => setRecipients(users.map(user => user.email))}
                        onClose={() => setIsAddingRecipients(false)}/>
      <NotificationWizard
        content={content}
        images={images}
        title={title}
        onSchedule={handleStartSchedule}
        onPostNow={handlePostNow}
        onSaveDraft={handleSaveDraft}
        onAttachImage={handleAttachImage}
        onDetachImage={handleDetachImage}
        onChangeTitle={e => setTitle(e.target.value)}
        onChangeContent={setContent}
        onReorderImages={setImages}
        isEditingPublishedNotification={isEditingPublishedNotification}
        onReset={handleReset}
        recipients={recipients}
        onDeleteRecipient={handleDeleteRecipient}
        onOpenRecipientsDialog={() => setIsAddingRecipients(true)}
        isEdit={Boolean(notificationId)}
        onSetIsBroadcast={value => setRecipients(value ? ["all"] : [])}
        isBroadcast={recipients.includes("all") && recipients.length === 1}/>
    </>
  );
});

export default notificationWizardContainer;