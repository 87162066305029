import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import AppBar from "./AppBar";
import Drawer from "./Drawer";
import {Redirect, Route, Switch} from "react-router-dom";
import NotificationSection from "../NotificationSection";
import DashboardSection from "../DashboardSection";
import Snackbar from "../shared/Snackbar";
import classNames from "classnames";
import {isWidthDown} from "../shared/mui";
import BottomNavigation from './BottomNavigation';
import {bool, func, object} from "prop-types";
import {keyBy, tail} from "lodash";
import StatusDialog from "../shared/StatusDialog";


const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    marginLeft: theme.metrics.collapsedDrawerWidth,
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      height: "auto",
    }
  },
  contentDesktop: {},
  contentShifted: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.metrics.drawerWidth,
    }
  },
  drawerShiftedMask: {
    display: "none",
    position: "fixed",
    zIndex: theme.zIndex.drawer - 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.action.disabledBackground,
    [theme.breakpoints.down('sm')]: {
      display: "initial",
    }
  },
  coreContent: {
    display: "flex",
    alignItems: "stretch",
    position: "relative",
    height: `calc(100vh - ${theme.metrics.appBarHeight}px)`,
    [theme.breakpoints.down('xs')]: {
      height: "auto",
      marginBottom: `${theme.metrics.mobileMenuHeight}px`,
    }
  },
  section: {
    flexGrow: 1,
    height: "100%",
    overflow: "auto",
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    }
  },
});


const ROUTES = [
  {
    path: "/notifications",
    name: "Notifications",
    exact: false,
    Component: NotificationSection
  },
  {path: "/", name: "Dashboard", exact: true, Component: DashboardSection},
];

const ROUTES_BY_PATH = keyBy(ROUTES, "path");

const getRoute = path => (path in ROUTES_BY_PATH) ? ROUTES_BY_PATH[path] : tail(ROUTES_BY_PATH);

const page = withStyles(styles)(
  ({
     isDrawerOpen, onOpenDrawer, onCloseDrawer, error, info, success, warning, onCloseInfo, onCloseWarning,
     onCloseSuccess, onCloseError, location, bigStatus, onCloseBigStatus, classes
   }) => {

    const isMobile = isWidthDown("xs");
    const contentCls = classNames(classes.content, isDrawerOpen && classes.contentShifted);
    return (
      <>
        {<StatusDialog title={bigStatus.title}
                       open={!bigStatus.handled}
                       variant={bigStatus.status}
                       onClose={onCloseBigStatus}>
          {bigStatus.message}
        </StatusDialog>}
        <Snackbar message={info.msg} isOpen={Boolean(info.show)}
                  onClose={onCloseInfo}
                  variant="info"/>
        <Snackbar message={warning.msg} isOpen={Boolean(warning.show)}
                  onClose={onCloseWarning}
                  variant="warning"/>
        <Snackbar message={success.msg} isOpen={Boolean(success.show)}
                  onClose={onCloseSuccess}
                  variant="success"/>
        <Snackbar message={error.message}
                  isOpen={error.show && ![401].includes(error.code)}
                  onClose={onCloseError} variant="error"/>
        <AppBar isShifted={isDrawerOpen}
                title={getRoute(location.pathname).name}/>
        {!isMobile && <Drawer isOpen={isDrawerOpen} onClose={onCloseDrawer}
                              onOpen={onOpenDrawer}/>}
        <main className={contentCls}>
          <div className={classes.appBarSpacer}/>
          <div className={classes.coreContent}>
            <div className={classes.section}>
              <Switch>
                {ROUTES.map(({path, exact, Component}) => (
                  <Route path={path} key={path}
                         exact={exact}><Component/></Route>
                ))}
                <Redirect to="/"/>
              </Switch>
            </div>
          </div>
        </main>
        {isMobile && <BottomNavigation/>}
      </>
    )
  }
);

page.propTypes = {
  isDrawerOpen: bool.isRequired,
  onOpenDrawer: func.isRequired,
  onCloseDrawer: func.isRequired,
  info: object,
  warning: object,
  success: object,
  error: object,
  onCloseInfo: func.isRequired,
  onCloseWarning: func.isRequired,
  onCloseSuccess: func.isRequired,
  onCloseError: func.isRequired,
  onCloseBigStatus: func.isRequired,
  bigStatus: object,
};

export default page;