import React from "react";
import {any, array, arrayOf, func, string} from "prop-types";
import TextField from "@material-ui/core/TextField";
import {List} from "@material-ui/core";

const APISearch = (
  {selection, options, onClickItem, onInputChange, textValue, inputLabel, helperText, itemRenderer}
) => (
  <div style={{marginBottom: 200}}>
    <TextField value={textValue} onChange={onInputChange} fullWidth
               margin="normal" label={inputLabel} autoFocus helperText={helperText}/>
    <List>
      {options.slice(0, 10).map(item => itemRenderer(item, onClickItem, selection.includes(item)))}
    </List>
  </div>
);

APISearch.propTypes = {
  selection: array,
  options: arrayOf(any).isRequired,
  onInputChange: func.isRequired,
  onClickItem: func.isRequired,
  inputLabel: string,
  helperText: string,
  textValue: string,
  itemRenderer: func.isRequired,
};

export default APISearch;
