import user from "./user";
import crop from "./crop";
import selectOption from './selectOption';
import tableHeadColumn from "./tableHeadColumn";
import notification from "./notification";
import attachment from "./attachment";
import supportCategory from './supportCategory';

export default {
  user,
  crop,
  selectOption,
  tableHeadColumn,
  notification,
  attachment,
  supportCategory,
};
