import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {any, bool, func, node, oneOf, string} from "prop-types";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import {Zoom} from "../../shared/Transitions"
import {Typography, withStyles} from "@material-ui/core";
import {noop} from "lodash";
import classnames from "classnames";

const styles = theme => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    paddingTop: theme.spacing(2),
  }
});

const simpleDialog = withStyles(styles)(withMobileDialog({breakpoint: 'xs'})(
  ({
     isOpen, onCancel, onSubmit, fullScreen, title, cancelLabel, forceFullScreen, classes,
     submitLabel, maxWidth, TransitionComponent, children, titleClassname, fullWidth, onClose,
    ...props
   }) => (
    <Dialog open={isOpen} fullScreen={forceFullScreen || fullScreen} maxWidth={maxWidth} {...props}
            TransitionComponent={TransitionComponent} fullWidth={fullWidth} onClose={onClose}>
      {title &&
      <DialogTitle disableTypography classes={{root: classnames(classes.root, titleClassname)}}>
        <Typography component="h4" variant="h4" color="inherit">{title}</Typography>
      </DialogTitle>}
      <DialogContent className={classes.content}>
        {children}
      </DialogContent>
      <DialogActions>
        {onCancel && <Button color="primary" onClick={onCancel}>{cancelLabel}</Button>}
        {onSubmit && <Button color="primary" onClick={onSubmit}>{submitLabel}</Button>}
      </DialogActions>
    </Dialog>
  )
));

simpleDialog.propTypes = {
  title: node,
  cancelLabel: string,
  submitLabel: string,
  isOpen: bool.isRequired,
  onCancel: func,
  onSubmit: func,
  maxWidth: oneOf(["xs", "sm", "md", "lg", "xl"]),
  TransitionComponent: any,
  forceFullScreen: bool,
  titleClassname: string,
  fullWidth: bool,
  onClose: func,
};

simpleDialog.defaultProps = {
  cancelLabel: "Cancel",
  submitLabel: "OK",
  maxWidth: "lg",
  TransitionComponent: Zoom,
  forceFullScreen: false,
  fullWidth: false,
  onClose: noop,
};

export default simpleDialog;
