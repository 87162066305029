import {
  GLOBAL_CLOSE_ERROR,
  GLOBAL_CLOSE_INFO,
  GLOBAL_CLOSE_SUCCESS,
  GLOBAL_CLOSE_WARNING,
  GLOBAL_SET_ERROR,
  GLOBAL_SET_FILTER_NOTIFICATION_STATUS,
  GLOBAL_SET_INFO,
  GLOBAL_SET_SUCCESS,
  GLOBAL_SET_WARNING,
  GLOBAL_SET_BIG_STATUS,
  GLOBAL_CLOSE_BIG_STATUS,
} from "./actionTypes";

export const setAjaxError = error => ({
  type: GLOBAL_SET_ERROR,
  val: {
    message: error.response ?
      error.response.data.message :
      "Unable to reach the server.",
    code: error.response ?
      error.response.status :
      500,
  }
});


export const setStringError = error => ({
  type: GLOBAL_SET_ERROR,
  val: {
    message: error,
    code: -1,
  }
});

export const setInfo = msg => ({
  type: GLOBAL_SET_INFO,
  val: msg,
});

export const setSuccess = msg => ({
  type: GLOBAL_SET_SUCCESS,
  val: msg,
});

export const setBigStatus = (status, title, message) => ({
  type: GLOBAL_SET_BIG_STATUS,
  val: {status, title, message},
});

export const setWarning = msg => ({
  type: GLOBAL_SET_WARNING,
  val: msg,
});

export const closeInfo = () => ({
  type: GLOBAL_CLOSE_INFO,
});

export const closeSuccess = () => ({
  type: GLOBAL_CLOSE_SUCCESS,
});

export const closeBigStatus = () => ({
  type: GLOBAL_CLOSE_BIG_STATUS,
});

export const closeWarning = () => ({
  type: GLOBAL_CLOSE_WARNING,
});

export const closeError = () => ({
  type: GLOBAL_CLOSE_ERROR,
});

export const setFilterNotificationStatus = status => ({
  type: GLOBAL_SET_FILTER_NOTIFICATION_STATUS,
  val: status,
});
