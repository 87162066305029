import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';
import TableToolbar from "./TableToolbar";
import TableBody from "./TableBody";
import TableHead from "./TableHead";
import {string, func, number, arrayOf, any, object, bool} from 'prop-types';
import shapes from "../../../shared/shapes";
import {isEmpty, noop} from "lodash";

const styles = () => ({
  table: {minWidth: 300},
  tableWrapper: {
    overflowX: 'auto',
    overflowY: "auto",
  },
});

const table = withStyles(styles)((
  {
    rows, orderDir, orderCol, selected, rowsPerPage, page, isSelected, onChangePage, selectable,
    onChangeRowsPerPage, onRowClick, onSelectAllClick, onRequestSort, columns, title, renderer,
    classes, noRowContent, sort, onDeleteRows, onEditRow, preventEdit, compact, onDuplicateRow,
    ...props
  }
) => (
  <div {...props}>
    <TableToolbar selected={selected} title={title} onEditRow={onEditRow} selectable={selectable}
                  onDeleteRows={onDeleteRows} preventEdit={preventEdit}
                  onDuplicateRow={onDuplicateRow}/>
    <div className={classes.tableWrapper}>
      <Table className={classes.table} aria-labelledby={title} size={compact? "small" : "medium"}>
        <TableHead numSelected={selected.length} order={orderDir} orderBy={orderCol}
                   onSelectAllClick={onSelectAllClick} onRequestSort={onRequestSort}
                   rowCount={rows.length} columns={columns}
                   selectable={selectable}/>
        <TableBody
          renderer={renderer} sort={sort} selectable={selectable}
          page={page} rowsPerPage={rowsPerPage} rows={rows} columns={columns}
          orderCol={orderCol} isSelected={isSelected} orderDir={orderDir} onClick={onRowClick}
          emptyRows={isEmpty(rows) && noRowContent ? 0 : rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)}/>
      </Table>
      {isEmpty(rows) && noRowContent}
    </div>
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]} component="div" count={rows.length}
      rowsPerPage={rowsPerPage} page={page}
      backIconButtonProps={{'aria-label': 'Previous Page'}} onChangePage={onChangePage}
      nextIconButtonProps={{'aria-label': 'Next Page'}} onChangeRowsPerPage={onChangeRowsPerPage}
    />
  </div>
));

table.propTypes = {
  title: string,
  rows: arrayOf(object).isRequired,
  columns: arrayOf(shapes.tableHeadColumn),
  orderDir: string.isRequired,
  orderCol: string.isRequired,
  selected: arrayOf(any).isRequired,
  rowsPerPage: number.isRequired,
  page: number.isRequired,
  isSelected: func.isRequired,
  onChangePage: func.isRequired,
  onChangeRowsPerPage: func.isRequired,
  onRowClick: func.isRequired,
  onSelectAllClick: func.isRequired,
  onRequestSort: func.isRequired,
  renderer: func,
  sort: func,
  emptyContent: any,
  onDeleteRows: func.isRequired,
  onEditRow: func.isRequired,
  preventEdit: func,
  compact: bool,
  selectable: bool.isRequired,
  onDuplicateRow: func.isRequired,
};

table.defaultProps = {
  preventEdit: noop,
};


export default table;
