import React from 'react';
import {withStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {isWidthDown} from "../shared/mui";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import WithIconAdornment from "../shared/hoc/WithIconAdornment";
import Grid from "@material-ui/core/Grid";
import NotificationsTable from "./NotificationsTable";
import {func, string} from "prop-types";
import SectionHolder from "../shared/SectionHolder/SectionHolder";
import NotificationFilters from "./NotificationFilters";
import NotificationWizard from "./NotificationWizard";

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(14),
  },
  controlSection: {
    justifyContent: "flex-start",
    display: "flex",
    [theme.breakpoints.down('xs')]: {
      display: "block",
      paddingRight: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5),
    },
  },
  searchBar: {
    paddingLeft: theme.spacing(0.5),
    [theme.breakpoints.down('xs')]: {
      display: "block",
      paddingRight: theme.spacing(0.5),
    },
    [theme.breakpoints.down('sm')]: {
      order: 3,
    },
  },
  notifications: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
    },
    border: `solid 1px ${theme.palette.divider}`,
    width: "100%",
    overflow: "hidden",
  },
});

const notificationSection = withStyles(styles)((
  {search, onChangeSearch, classes}
) => {
  const isMobile = isWidthDown("xs");
  const controlsMargin = isMobile ? "dense" : "normal";
  return (
    <div className={classes.root}>
      <SectionHolder title="Notifications platform">
        <NotificationWizard/>
        <Grid container>
          <Grid item xs={12} sm md="auto" className={classes.controlSection}>
            <NotificationFilters/>
          </Grid>
          <Grid item xs={12} sm={12} md className={classes.searchBar}>
            <WithIconAdornment Icon={SearchIcon} position="end">
              <TextField placeholder="Just 2 letters to get started..."
                         label="Search" fullWidth
                         variant="outlined" margin={controlsMargin}
                         value={search}
                         onChange={onChangeSearch}/>
            </WithIconAdornment>
          </Grid>
        </Grid>
        <Paper elevation={0} square={isMobile}
               className={classes.notifications}>
          <NotificationsTable search={search}/>
        </Paper>
      </SectionHolder>
    </div>
  );
});

notificationSection.propTypes = {
  search: string,
  onChangeSearch: func.isRequired,
};

notificationSection.defaultProps = {
};

export default notificationSection;