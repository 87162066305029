import React from 'react';
import {func, oneOf, string} from 'prop-types';
import {noop} from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import SuccessIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import {Fade, Zoom} from "../../shared/Transitions";

const VARIANTS = {
  "success": {Icon: SuccessIcon, color: "green"},
  "error": {Icon: ErrorIcon, color: "red"},
};

const styles = {
  icon: {
    margin: "auto",
    minHeight: "4rem",
    minWidth: "4rem",
    borderRadius: "2rem",
    borderWidth: "4px",
    padding: 6,
    borderStyle: "solid",
  }
};

const statusDialog = withStyles(styles)((
  {variant, title, onClose, classes, open, children}
) => {

  const {Icon, color} = VARIANTS[variant];

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="confirm-dialog-title" maxWidth="sm"
            fullWidth
            aria-describedby="confirm-dialog-description">
      <DialogTitle disableTypography id="confirm-dialog-title">
        <Zoom in timeout={500} style={{transitionDelay: '200ms'}}>
          <Typography align="center" style={{color, borderColor: color}}>
            <Icon className={classes.icon}/>
          </Typography>
        </Zoom>
        <Fade in timeout={500} style={{transitionDelay: '1000ms'}}>
          <Typography align="center" style={{color, borderColor: color}}>{title}</Typography>
        </Fade>
      </DialogTitle>
      <DialogContent>
        <Fade in timeout={500} style={{transitionDelay: '1000ms'}}>
          {children && <DialogContentText align="center">{children}</DialogContentText>}
        </Fade>
      </DialogContent>
    </Dialog>
  );
});

statusDialog.propTypes = {
  variant: oneOf(["success", "error"]),
  title: string.isRequired,
  onClose: func.isRequired,
};

statusDialog.defaultProps = {
  onCancel: noop,
  variant: "success",
};

export default statusDialog;
