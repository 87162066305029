import React from 'react';
import {oneOf, string} from 'prop-types';
import {Typography} from "@material-ui/core";

const TextPreview = ({variant, children}) => (
  <Typography variant={variant}>
      {children.split('\n').map((chunk, index) => (
      <React.Fragment key={index}>{chunk}<br/></React.Fragment>
    ))}
  </Typography>
);

TextPreview.propTypes = {
  text: string,
  variant: oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'caption',
    'button',
    'overline',
    'srOnly',
    'inherit',
  ]),
};

TextPreview.defaultProps = {
  variant: "body1",
};

export default TextPreview;