import {isEqual, isObject, transform} from "lodash";


/**
 * Returns an object which contains all attributes of "updates" which are missing or different from
 * the base object. Beware: The check is shallow.
 * @param base {*} The base to compare.
 * @param updates {*} The updates to compare.
 * @return {{}} An object with only the differences (and the adds) in updates object.
 */
export const difference = (base, updates) => (
  transform(updates, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] = (isObject(value) && isObject(base[key])) ? difference(base[key], value) : value;
    }
  })
);

export const ellipsis = (text, maxLength) => (
  !text ? text : (text.length <= maxLength ? text : (text.substr(0, maxLength) + "…"))
);

/**
 *
 * @param {[*]} array The array to work on. It will not be modified.
 * @param {number} index Required. An integer that specifies at what position to add/remove items,
 * Use negative values to specify the position from the end of the array
 * @param {number} howmany  Optional. The number of items to be removed. If set to 0,
 * no items will be removed
 * @param items Optional. The new item(s) to be added to the array
 * @return {[*]} The new array.
 */
export const splice = (array, index, howmany, ...items) => {
  return [
    ...array.slice(0, index),
    ...items,
    ...array.slice(index + howmany)
  ]
};

