import {
  NOTIFICATION_ADD,
  NOTIFICATION_DELETE,
  NOTIFICATION_EDIT,
  NOTIFICATION_LOAD_ALL,
  NOTIFICATION_SET_STATE,
} from "./actionTypes";
import {withCatchAjaxError} from "./util";
import ajax, {uploadFile} from "../../shared/util/ajax";
import {setBigStatus, setSuccess} from "./global";
import {omit, partition} from "lodash";

export const setNotificationWizardState = (notificationId) => ({
  type: NOTIFICATION_SET_STATE, val: {notificationId}
});

const addNotification = notification => ({
  type: NOTIFICATION_ADD,
  val: notification
});

const loadNotifications = notifications => ({
  type: NOTIFICATION_LOAD_ALL,
  val: notifications
});

const editNotification = notification => ({
  type: NOTIFICATION_EDIT,
  val: notification
});

const deleteNotification = notificationId => ({
  type: NOTIFICATION_DELETE,
  val: notificationId
});

const dispatchNotificationSuccess = (notification, dispatch) => {
  switch (notification.status) {
    case "scheduled":
      dispatch(setBigStatus(
        "success",
        "Notification successfully scheduled",
        "It is now visible in the calendar."
      ));
      break;
    case "draft":
      dispatch(setBigStatus(
        "success",
        "Notification successfully saved as draft",
        "Pick it from the main list to edit it again"
      ));
      break;
    case "published":
      dispatch(setBigStatus(
        "success",
        "Notification successfully published",
        "It should be visible now."
      ));
      break;
    default:
      break;
  }
};


const uploadVideos = async videoFiles => {
  const promises = videoFiles.map(file => uploadFile('/misc/media', file));
  const responses = await Promise.all(promises);
  return responses.map(response => response.data.media_ref);
};

export const addNotificationAsync = notification => dispatch => {
  uploadVideos(notification.videos)
    .then(videoMediaRefs => {
      notification = {
        ...omit(notification, "videos"),
        media_refs: videoMediaRefs
      };
      ajax.post('/notification', notification).then(response => {
        dispatch(addNotification(response.data));
        dispatchNotificationSuccess(notification, dispatch);
      })
        .catch(e => {
          dispatch(setBigStatus(
            "error",
            "Unable to create notification",
            e.response.data.message
          ));
        });
    })
};

export const editNotificationAsync = notification => dispatch => {
  const notificationId = notification.id;
  const [videoURLs, videoFiles] = partition(notification.videos, v => typeof v === "string");
  uploadVideos(videoFiles)
    .then(videoMediaRefs => {
      notification = {
        ...omit(notification, "id"),
        videos: videoURLs,
        media_refs: videoMediaRefs
      };
      ajax.put(`/notification/${notificationId}`, notification).then(response => {
        dispatch(editNotification(response.data));
        dispatchNotificationSuccess(notification, dispatch);
      })
        .catch(e => {
          dispatch(setBigStatus(
            "error",
            "Unable to edit notification",
            e.response.data.message,
          ));
        });
    })
};

export const loadAllNotificationsAsync = () => {

  return dispatch => {
    withCatchAjaxError(dispatch)(
      ajax.get('/notification/admin').then(
        response => dispatch(loadNotifications(response.data))
      )
    )
  };
};

export const deleteNotificationAsync = notificationId => {
  return dispatch => {
    withCatchAjaxError(dispatch)(
      ajax.delete(`/notification/${notificationId}`).then(
        () => {
          dispatch(deleteNotification(notificationId));
          dispatch(setBigStatus(
            "success",
            "Notification(s) successfully deleted and placed in the bin",
            "If it was published it is been removed from the target channel."
          ));
        }
      )
    )
  };
};

export const duplicateNotificationAsync = notificationId => {
  return dispatch => {
    withCatchAjaxError(dispatch)(
      ajax.post(`/notification/${notificationId}/duplicate`).then(
        response => {
          dispatch(addNotification(response.data));
          dispatch(setSuccess("Notification successfully duplicated and saved in drafts."));
        }
      )
    )
  };
};