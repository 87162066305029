import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {DialogContentText} from "@material-ui/core";
import {oneOf} from "prop-types";

const disconnectedDialog = ({error}) => {

  setTimeout(
    () => window.location.href = "/login",
    5000,
    );

  return (
  <Dialog open aria-labelledby="form-dialog-title"
          disableBackdropClick disableEscapeKeyDown>
    <DialogTitle id="form-dialog-title">
      {error === "auth" && "You have been disconnected."}
      {error === "server" && "Oups! The server seems down..."}
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
         {error === "server" && "We apologize and are doing our best to tidy up."}
         {error === "server" && <br/>}
        You will soon be redirected to the the login page.
        
        <a style={{display: "block"}} href={"/login"}>
          Click here if you want to go now
        </a>
      </DialogContentText>
    </DialogContent>
  </Dialog>
  );
};

disconnectedDialog.propTypes = {
  error: oneOf(["auth", "server"]),
};

disconnectedDialog.defaultProps = {
    error: "auth",
};

export default disconnectedDialog
