import ajax from "../../shared/util/ajax";
import {PROFILE_LOAD_USER} from "./actionTypes";
import {withCatchAjaxError} from "./util";
import {setInfo, setSuccess} from "./global";
import {isEmpty} from "lodash";


const loadUser = profile => {
  return {type: PROFILE_LOAD_USER, val: profile}
};

export const loadUserAsync = () => {

  return dispatch => {  
    withCatchAjaxError(dispatch)(
      ajax.get('/profile/me').then(
        response => dispatch(loadUser(response.data))
      )
    )
  };
};

export const saveProfileAsync = profileDiff => {
  if (isEmpty(profileDiff)) {
    return dispatch => {
      dispatch(setInfo("No change found."));
    }
  }

  return dispatch => {
    withCatchAjaxError(dispatch)(
      ajax.put('/profile/me', profileDiff).then(
        response => {
          dispatch(loadUser(response.data));
          dispatch(setSuccess("Your profile has been updated."));
        }
      )
    )
  };
};
