import React, {useState} from "react";
import NotificationSection from './NotificationSection';

const notificationSectionContainer = () => {
  const [search, setSearch] = useState("");

  return (
    <NotificationSection search={search} onChangeSearch={e => setSearch(e.target.value)}/>
  );
};

export default notificationSectionContainer;
