import React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import {noop, orderBy} from "lodash";
import {arrayOf, bool, func, number, object, string} from "prop-types";
import shapes from "../../../../shared/shapes";
import {Grow} from "../../../shared/Transitions";

const tableBody = (
  {
    rows, orderDir, page, rowsPerPage, orderCol, emptyRows, isSelected, onClick, columns, renderer,
    sort, selectable
  }
) => (
  <TableBody>
    {(sort || orderBy)(rows, orderCol, orderDir)
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map(row => (
        <Grow in mountOnEnter unmountOnExit key={row.id} timeout={300}>
        <TableRow hover onClick={!selectable ? noop :() => onClick(row)} role="checkbox"
                  aria-checked={isSelected(row)} tabIndex={-1}  selected={isSelected(row)}>
          {selectable &&
          <TableCell padding="checkbox"><Checkbox checked={isSelected(row)}/></TableCell>}
          {columns.map((col, index) => (
            <TableCell component={index > 0 ? "td" : "th"} key={col.id}
                       scope="row" align={col.numeric ? "right" : "left"}
                       padding="default">
              {renderer(row, col)}
            </TableCell>
          ))}
        </TableRow>
        </Grow>
      ))}
    {emptyRows !== 0 &&
    <TableRow style={{height: 49 * emptyRows}}><TableCell colSpan={6}/></TableRow>
    }
  </TableBody>
);

tableBody.propTypes = {
  rows: arrayOf(object).isRequired,
  columns: arrayOf(shapes.tableHeadColumn).isRequired,
  orderDir: string.isRequired,
  orderCol: string.isRequired,
  emptyRows: number.isRequired,
  isSelected: func.isRequired,
  onClick: func.isRequired,
  page: number.isRequired,
  rowsPerPage: number.isRequired,
  renderer: func,
  sort: func,
  selectable: bool.isRequired,
};

tableBody.defaultProps = {
  renderer: (row, col) => row[col.id].toString()
};

export default tableBody;