import * as React from "react";
import {any, element, oneOf, string} from "prop-types";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";

const withIconAdornment = ({Icon, position, tooltip, className, children, InputProps, ...props}) => (
  React.cloneElement(children, {
    ...props,
    InputProps: {
      ...InputProps,
      [position === "start" ? "startAdornment" : "endAdornment"]: (
        <>
          {
            tooltip ?
              <Tooltip title={tooltip}>
                <InputAdornment position={position}><Icon className={className}/></InputAdornment>
              </Tooltip>
              :
              <InputAdornment position={position}><Icon className={className}/></InputAdornment>

          }
        </>
      ),
    }
  })
);

withIconAdornment.propTypes = {
  children: element.isRequired,
  Icon: any.isRequired,
  position: oneOf(["start", "end"]),
  tooltip: string,
  className: string,
};

withIconAdornment.defaultProps = {
  position: "start"
};

export default withIconAdornment;
