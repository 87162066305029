// Global
export const GLOBAL_SET_INFO = 'GLOBAL_SET_INFO';
export const GLOBAL_SET_SUCCESS = 'GLOBAL_SET_SUCCESS';
export const GLOBAL_SET_WARNING = 'GLOBAL_SET_WARNING';
export const GLOBAL_SET_ERROR = 'GLOBAL_SET_ERROR';
export const GLOBAL_CLOSE_INFO = 'GLOBAL_CLOSE_INFO';
export const GLOBAL_CLOSE_SUCCESS = 'GLOBAL_CLOSE_SUCCESS';
export const GLOBAL_CLOSE_WARNING = 'GLOBAL_CLOSE_WARNING';
export const GLOBAL_CLOSE_ERROR = 'GLOBAL_CLOSE_ERROR';
export const GLOBAL_SET_BIG_STATUS = 'GLOBAL_SET_BIG_STATUS';
export const GLOBAL_CLOSE_BIG_STATUS = 'GLOBAL_CLOSE_BIG_STATUS';

export const GLOBAL_SET_FILTER_NOTIFICATION_STATUS = 'GLOBAL_SET_FILTER_NOTIFICATION_STATUS';

// Profile
export const PROFILE_LOAD_USER = 'PROFILE_LOAD_USER';

// Notifications
export const NOTIFICATION_LOAD_ALL = "NOTIFICATION_LOAD_ALL";
export const NOTIFICATION_ADD =  'NOTIFICATION_ADD';
export const NOTIFICATION_EDIT = 'NOTIFICATION_EDIT';
export const NOTIFICATION_DELETE = 'NOTIFICATION_DELETE';
export const NOTIFICATION_SET_STATE = 'NOTIFICATION_SET_STATE';
