import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {any, bool, func, string} from "prop-types";
import {flow, noop} from "lodash";


const confirmDialog = (
  {title, yesLabel, noLabel, native, onConfirm, onCancel, onClose, children}
) => {
  if (native) {
    (window.confirm(`${title}\n${children}`) ? onConfirm : onCancel)();
    onClose();
    return <React.Fragment/>;
  }

  return (
    <Dialog open onClose={onCancel} aria-labelledby="confirm-dialog-title" maxWidth="sm" fullWidth
            aria-describedby="confirm-dialog-description">
      {title && <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">{children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={flow(onCancel, onClose)} color="primary">{noLabel}</Button>
        <Button onClick={flow(onConfirm, onClose)} color="primary" autoFocus>{yesLabel}</Button>
      </DialogActions>
    </Dialog>
  );
};

confirmDialog.propTypes = {
  title: string,
  children: any,
  yesLabel: string,
  noLabel: string,
  native: bool,
  onConfirm: func.isRequired,
  onClose: func,
  onCancel: func,
};

confirmDialog.defaultProps = {
  title: "",
  yesLabel: "Agree",
  noLabel: "Disagree",
  native: false,
  onCancel: noop,
  onClose: noop,
};

export default confirmDialog;
