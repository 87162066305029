import React from 'react';
import NotificationCell from "./NotificationCell";
import MediaCell from "./MediaCell";
import {withStyles} from "@material-ui/core";
import shapes from "../../../../shared/shapes"
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    marginRight: -24,
    textAlign: "center",
  },
  account: {
    display: "inline-block",
  }
});

const mobileNotificationCell = withStyles(styles)((
  {notification, classes}
) => (
  <div className={classes.root}>
    <Typography>{notification.recipients.join(', ')}</Typography>
    <NotificationCell content={notification.content} link={notification.link} title={notification.title}/>
    <MediaCell attachments={notification.attachments} link={notification.link}/>
  </div>
));

mobileNotificationCell.propTypes = {
  notification: shapes.notification,
};

export default mobileNotificationCell;