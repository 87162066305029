import React, {Component} from 'react';
import Splash from './Splash';
import {delay} from "lodash";

class SplashContainer extends Component {

  unmounted = false;

  state = {
    msg: "Please wait...",
  };

  componentWillUnmount = () => this.unmounted = true;

  setMsg = msg => {
    if (!this.unmounted) {
      this.setState({msg})
    }
  };

  componentDidMount() {
    delay(() => this.setMsg("Unusual. Are you sure you are connected to the internet?"), 5000);
    delay(() => this.setMsg("It is getting late... Did you go through a tunnel or something?"), 15000);
    delay(() => this.setMsg("Really, you should refresh the page now."), 30000);
  }

  render = () => <Splash message={this.state.msg}/>;
}


export default SplashContainer;