import React, {useRef} from 'react';
import {Typography, withStyles} from "@material-ui/core";
import AddPhotoIcon from "@material-ui/icons/AddPhotoAlternate";
import EmojiIcon from "@material-ui/icons/SentimentSatisfied";
import SendIcon from "@material-ui/icons/Send";
import {arrayOf, bool, func, string} from "prop-types";
import MessageBox from "./Controls/MessageBox";
import Tooltip from "@material-ui/core/Tooltip";
import EmojiKeyboard from "../../../shared/EmojiKeyboard";
import Divider from "@material-ui/core/Divider";
import ImagePicker from "../../../shared/hoc/ImagePicker";
import {isEmpty} from "lodash";
import TextField from "@material-ui/core/TextField";
import ImageList from "../../../shared/ImageList";
import LoadingButton from "../../../shared/LoadingButton";
import Button from "./Controls/Button";
import LinkPreview from "../../../shared/LinkPreview";
import Chip from "@material-ui/core/Chip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";


const styles = theme => ({
  content: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  statusBar: {
    backgroundColor: theme.shades.darker[5],
  },
  controls: {},
  captions: {
    "& p": {
      paddingLeft: theme.spacing(1),
      color: theme.palette.text.secondary,
      transition: "height 0.3s, opacity 0.3s",
      overflowY: "hidden",
      height: 0,
      opacity: 0,
      "& span": {
        display: "inline-block",
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        border: `solid 1px ${theme.palette.secondary.light}`,
        borderRadius: 4,
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
      }
    }
  },

  tooltip: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  vAccordion: {
    transition: "height 0.3s ease",
    overflow: "hidden",
    textAlign: "right",
    "& button": {
      height: "60px",
    }
  },
  titleTextfieldInput: {
    paddingLeft: theme.spacing(0.5),
  },

  titleTextfieldFormHelper: {
    marginTop: 0,
    padding: theme.spacing(0.5),
  },
  postButton: {
    margin: theme.spacing(0.5),
  },
  titleHelpIcon: {
    cursor: "help",
    margin: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  recipientsBox: {
    display: "inline-block",
    flexGrow: 1,
  },
  recipients: {
    display: "flex",
    alignItems: "center",
  },
});

const notificationCreator = withStyles(styles)((
  {
    classes, content, onChangeContent, isEmojiKeyboardOpen, videos,
    onOpenEmojiKeyboard, onCloseEmojiKeyboard, onSelectEmoji, messageBoxRef, onRequestSchedule,
    schedulePaneOpen, onCancelSchedule, onAttachImage, onSaveDraft,
    onPostNow, onSchedule, images, onDetachImage, link, title, onChangeTitle, postingLocked,
    onReorderImages, recipients, onOpenRecipientsDialog, onDeleteRecipient, isBroadcast, onSetIsBroadcast
  }) => {

  const controlsRef = useRef();

  return (
    <>
      <div className={classes.content}>
        <div className={classes.recipients}>
          <Button onClick={onOpenRecipientsDialog}>Add recipients...</Button>
          <div className={classes.recipientsBox}>
            {recipients
              .filter(recipient => recipient !== "all")
              .map(recipient => (
              <Chip key={recipient} label={recipient}
                    onDelete={() => onDeleteRecipient(recipient)}/>
            ))}
          </div>
          <FormControlLabel
            control={
              <Checkbox checked={isBroadcast}
                        onChange={() => onSetIsBroadcast(!isBroadcast)} value=""/>
            }
            label="All"
          />
        </div>
        <Divider/>
        <TextField label="Title"
                   value={title} onChange={onChangeTitle}
                   FormHelperTextProps={{className: classes.titleTextfieldFormHelper}}
                   InputLabelProps={{className: classes.titleTextfieldInput}}/>
        <MessageBox ref={messageBoxRef}
                    content={content}
                    onChangeContent={onChangeContent}/>
        <div className={classes.statusBar}>
          <div className={classes.captions}>
            <div style={link ? {
              height: isEmpty(images) && isEmpty(videos) ? 135 : 24,
              opacity: 1
            } : undefined}>
              <Typography variant="caption" component="p">
                Link found: {link && <span>{link}</span>}
              </Typography>
              {link && isEmpty(images) && isEmpty(videos) &&
              <div className={classes.preview}><LinkPreview link={link}/>
              </div>}
            </div>
          </div>
          {!isEmpty(images) &&
          <>
            <Divider/>
            <ImageList images={images}
                       onImageClick={onDetachImage}
                       onReorderImages={onReorderImages}/>
          </>}
          <div className={classes.vAccordion}
               style={{height: schedulePaneOpen ? "70px" : 0}}>
            <LoadingButton className={classes.postButton} color="default"
                           variant="contained"
                           loading={postingLocked} onClick={onSaveDraft}>
              Save Draft
            </LoadingButton>
            <LoadingButton className={classes.postButton} color="default"
                           variant="contained"
                           loading={postingLocked} onClick={onPostNow}>
              {"Post now"}
            </LoadingButton>
            <LoadingButton className={classes.postButton} color="primary"
                           variant="contained"
                           loading={postingLocked} onClick={onSchedule}>
              Schedule...
            </LoadingButton>
          </div>
          <Typography align="right" component="div" ref={controlsRef}
                      className={classes.controls}>
            <Tooltip
              interactive
              open={isEmojiKeyboardOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              placement="top-end"
              TransitionProps={{timeout: 300}}
              classes={{tooltip: classes.tooltip}}
              title={
                <EmojiKeyboard
                  includeRecent={false}
                  onSelectEmoji={onSelectEmoji}
                  onClose={onCloseEmojiKeyboard}/>
              }>
              <Button Icon={EmojiIcon}
                      color={isEmojiKeyboardOpen ? "secondary" : "default"}
                      onClick={isEmojiKeyboardOpen ? onCloseEmojiKeyboard : onOpenEmojiKeyboard}/>
            </Tooltip>
            <ImagePicker onUpload={onAttachImage}
                         multiple>
              <Button Icon={AddPhotoIcon} color="default"
                      title="Add image..."/>
            </ImagePicker>
            <Button Icon={SendIcon} title="Schedule..."
                    color={schedulePaneOpen ? "secondary" : "primary"}
                    onClick={schedulePaneOpen ? onCancelSchedule : onRequestSchedule}/>
          </Typography>
        </div>
      </div>
    </>
  );
});

notificationCreator.propTypes = {
  content: string.isRequired,
  onChangeContent: func.isRequired,
  isEmojiKeyboardOpen: bool.isRequired,
  onOpenEmojiKeyboard: func.isRequired,
  onCloseEmojiKeyboard: func.isRequired,
  onSelectEmoji: func.isRequired,
  onRequestSchedule: func.isRequired,
  onCancelSchedule: func.isRequired,
  schedulePaneOpen: bool.isRequired,
  onAttachImage: func.isRequired,
  onSaveDraft: func.isRequired,
  onPostNow: func.isRequired,
  onSchedule: func.isRequired,
  onDetachImage: func.isRequired,
  images: arrayOf(string),
  link: string,
  title: string,
  onChangeTitle: func.isRequired,
  postingLocked: bool,
  onReorderImages: func.isRequired,
  recipients: arrayOf(string),
  onOpenRecipientsDialog: func.isRequired,
  onDeleteRecipient: func.isRequired,
  isBroadcast: bool,
  onSetIsBroadcast: func.isRequired,
};


export default notificationCreator;