import React, {useState} from 'react';
import ReportBugDialog from './ReportBugDialog';
import {func, bool} from 'prop-types';
import {flow, head, isEmpty, without} from "lodash";
import {checkBugDescription, checkBugTitle} from "../../../../shared/util/validation";
import {connect} from "react-redux";
import ajax from "../../../../shared/util/ajax";
import {setAjaxError, setSuccess} from "../../../../store/actions/global";

const mapDispatchToProps = dispatch => ({
  onSuccess: msg => dispatch(setSuccess(msg)),
  onError: err => dispatch(setAjaxError(err)),
});

const reportBugDialogContainer = connect(null, mapDispatchToProps)((
  {open, onClose, onSuccess, onError}
) => {
  const [images, setImages] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [titleError, setTitleError] = useState(undefined);
  const [descriptionError, setDescriptionError] = useState(undefined);

  const handleClose = () => {
    setImages([]);
    setTitle("");
    setDescription("");
    setTitleError(undefined);
    setDescriptionError(undefined);
    onClose();
  };

  const handleSubmit = () => {
    const titleErrors = checkBugTitle("Title", title);
    const descriptionErrors = checkBugDescription("Description", description);

    setTitleError(head(titleErrors));
    setDescriptionError(head(descriptionErrors));

    if (isEmpty(titleErrors) && isEmpty(descriptionErrors)) {
      ajax.post('/misc/bug', {title, description, images})
        .then(flow(() => onSuccess("Bug successfully reported. Thank you."), handleClose))
        .catch(onError);
    }
  };

  return (
    <ReportBugDialog
      open={open}
      images={images}
      description={description}
      title={title}
      titleError={titleError}
      descriptionError={descriptionError}
      onAttachImage={newImages => setImages([...images, ...newImages])}
      onDetachImage={image => setImages(without(images, image))}
      onChangeTitle={e => setTitle(e.currentTarget.value)}
      onChangeDescription={e => setDescription(e.currentTarget.value)}
      onReorderImages={setImages}
      onClose={handleClose}
      onSubmit={handleSubmit}/>
  );
});

reportBugDialogContainer.propTypes = {
  onClose: func.isRequired,
  open: bool.isRequired,
};

export default reportBugDialogContainer;