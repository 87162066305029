import React from 'react';
import {arrayOf, func, number, string} from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import Badge from "@material-ui/core/Badge";
import CloseIcon from "@material-ui/icons/Close";
import classnames from 'classnames';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';

const styles = theme => ({
  root: props => ({
    backgroundColor: props.images.length > props.maxImages ? theme.palette.error.light : "transparent",
    "& img": {
      maxHeight: 50,
      borderRadius: 3,
    },
  }),
  attachmentBadgeRoot: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& svg": {fontSize: "9pt"},
  },

});

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 0;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,

  // styles we need to apply on draggables
  ...draggableStyle,

  // position: "static",
});

const listStyle = {
  display: 'flex',
  padding: grid,
  overflow: 'auto',
};

const imageList = withStyles(styles)(({images, maxImages, onImageClick, classes, className, onReorderImages, ...props}) => {

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(images, result.source.index, result.destination.index);
    onReorderImages(items)
  };

  return <div className={classnames(classes.root, className)} {...props}
              title={images.length > maxImages ? "You are over the limit. Please remove items." : undefined}>
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided) => (
          <div ref={provided.innerRef} style={listStyle} {...provided.droppableProps}>
            {images.map((image, index) => (
              <Draggable key={image} draggableId={image} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                    <Badge badgeContent={<CloseIcon onClick={() => onImageClick(image)}/>}
                           color="secondary"
                           classes={{root: classes.attachmentBadgeRoot}}>
                      <img src={image} alt="attachment"/>
                    </Badge>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  </div>;
});

imageList.propTypes = {
  images: arrayOf(string).isRequired,
  onImageClick: func.isRequired,
  onReorderImages: func.isRequired,
  maxImages: number,
};

export default imageList;