import React, {useState} from "react";
import CropImage from "./CropImage";
import {bool, func, number, string} from "prop-types";
import {cropImage} from "../../../shared/util/images";
import {noop} from "lodash";

const cropImageContainer = ({square, onCropUpdated, ...props}) => {
  const [crop, setCrop] = useState({});
  const [imageEl, setImageEl] = useState(null);

  const cropAdjustments = {
    aspect: square ? 1 : undefined,
    height: square ? crop.width : crop.height,
  };

  return (
      <CropImage
        {...props}
        crop={{...crop, ...cropAdjustments}}
        onChange={setCrop}
        onComplete={crop => imageEl && onCropUpdated(cropImage(imageEl, crop))}
        onImageLoaded={setImageEl}
      />
  );
};

cropImageContainer.propTypes = {
  src: string,
  square: bool,
  onCropUpdated: func,
  minWidth: number,
  minHeight: number,
  maxWidth: number,
  maxHeight: number,
  keepSelection: bool,
  locked: bool,
};

cropImageContainer.defaultProps = {
  onCropUpdated: noop,
};


export default cropImageContainer;
