import React from 'react';
import Paper from "@material-ui/core/Paper";
import {withStyles} from "@material-ui/core";
import {arrayOf, bool, func, string} from "prop-types";
import Typography from "@material-ui/core/Typography";
import NotificationCreator from "./NotificationCreator";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  paper: {
    height: "100%",
    display: "flex",
    border: `solid 1px ${theme.palette.divider}`,
    flexDirection: "column",
  },
  header: {
    display: "flex",
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    color: "white",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  }

});


const notificationWizard = withStyles(styles)((
  {
    classes, isEdit, link, onReset,
    content, onChangeContent, images, onPostNow, onSaveDraft, onSchedule, onPreview, onAttachImage,
    onDetachImage, title, onChangeTitle, onReorderImages, recipients, onOpenRecipientsDialog,
    onDeleteRecipient, isBroadcast, onSetIsBroadcast
  }
) => {
  return (
    <Paper elevation={0} className={classes.paper}>
      <div className={classes.header}>
        <Typography variant="h5">
          {isEdit ? "Edit notification" : "Write new notification"}
        </Typography>
        <div style={{flexGrow: 1}}/>
        <Button onClick={onReset} variant="outlined"
                color="inherit">Reset</Button>
      </div>
      <div>
        <NotificationCreator
          onReorderImages={onReorderImages}
          onChangeContent={onChangeContent}
          content={content}
          images={images}
          title={title}
          onAttachImage={onAttachImage}
          onPostNow={onPostNow}
          onPreview={onPreview}
          onSaveDraft={onSaveDraft}
          onSchedule={onSchedule}
          onDetachImage={onDetachImage}
          link={link}
          onChangeTitle={onChangeTitle}
          recipients={recipients}
          onDeleteRecipient={onDeleteRecipient}
          onOpenRecipientsDialog={onOpenRecipientsDialog}
          onSetIsBroadcast={onSetIsBroadcast}
          isBroadcast={isBroadcast}/>
      </div>
    </Paper>
  );
});

notificationWizard.propTypes = {
  isEdit: bool,
  recipients: arrayOf(string),
  onOpenRecipientsDialog: func.isRequired,
  onDeleteRecipient: func.isRequired,
  isBroadcast: bool,
  onSetIsBroadcast: func.isRequired,
};


export default notificationWizard;