import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import {isWidthDown as _isWidthDown, isWidthUp as _isWidthUp} from "@material-ui/core/withWidth";

/**
 * Returns The current breakpoint.
 * @return {"xs" | "sm" | "md" | "lg" | "xl"}
 */
export const useWidth = () => {
  const theme = useTheme();
  return [...theme.breakpoints.keys].reverse().reduce((output, key) => {
      const matches = useMediaQuery(theme.breakpoints.only(key));

      return !output && matches ? key : output;
    }, null) || 'xs';
};

/**
 * Tells whether the current screen size is at least the one specified.
 * @param {"xs" | "sm" | "md" | "lg" | "xl"} bp
 * @return {boolean}
 */
export const isWidthUp = bp => _isWidthUp(bp, useWidth());

/**
 * Tells whether the current screen size is below the one specified.
 * @param {"xs" | "sm" | "md" | "lg" | "xl"} bp
 * @return {boolean}
 */
export const isWidthDown = bp => _isWidthDown(bp, useWidth());