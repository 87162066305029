import React, {useEffect, useState} from "react";
import CropImageDialog from "./CropImageDialog";
import {bool, func, string} from "prop-types";
import {rotateLeft, rotateRight} from "../../../shared/util/images";

const cropImageDialogContainer = ({isOpen, onCancel, onSubmit, srcImage, square}) => {

  const [croppedImage, setCroppedImage] = useState(srcImage);
  const [wholeImage, setWholeImage] = useState(srcImage);
  const [forceSquare, setForceSquare] = useState(false);

  useEffect(() => {
    setCroppedImage(srcImage);
    setWholeImage(srcImage);
  }, [srcImage]);

  const handleRotateLeft = async () => {
    setWholeImage(await rotateLeft(wholeImage));
  };
  const handleRotateRight = async () => {
    setWholeImage(await rotateRight(wholeImage));
  };

  return (
    <CropImageDialog
      isOpen={isOpen}
      srcImage={wholeImage}
      onSubmit={() => onSubmit(croppedImage)}
      onCancel={onCancel}
      onCropUpdated={setCroppedImage}
      square={square}
      onRotateLeft={handleRotateLeft}
      onRotateRight={handleRotateRight}
      forceSquare={forceSquare}
      onSetForceSquare={setForceSquare}
    />
  )
};

cropImageDialogContainer.propTypes = {
  srcImage: string,
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
  isOpen: bool,
  square: bool,
};

cropImageDialogContainer.defaultProps = {
  isOpen: false,
  srcImage: null,
  square: false,
};

export default cropImageDialogContainer;