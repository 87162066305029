import React from "react";
import {Typography, withStyles} from "@material-ui/core";
import DefaultIcon from "@material-ui/icons/BlurOn";
import {object, oneOf, oneOfType, string} from "prop-types";
import classnames from "classnames";

const styles = theme => ({
  root: {
    marginTop: theme.spacing(3),
    textAlign: "center",
    margin: "auto",
    "& img": {
      borderRadius: 4,
    }
  },
  small: {
    "& img": {width: "64px"},
    "& svg": {fontSize: "2.5rem"},
  },
  medium: {
    "& img": {width: "128px"},
    "& svg": {fontSize: "5rem"},
  },
  large: {
    "& img": {width: "256px"},
    "& svg": {fontSize: "10rem"},
  },
  auto: {},
  default: {
    animation: theme.animations.grayscale + " 3s",
    color: theme.palette.primary.light,
  },
  inherit: {
    color: "inherit",
  },
  primary: {
    color: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.secondary.main,
  }
});

const bigStatement = withStyles(styles)(({Icon, title, subtitle, className, size, color, classes, ...props}) => (
  <div className={classnames(classes.root, className)} {...props}>
    <div className={classnames(classes[size], classes[color])}>
      {typeof Icon === "string" ? <img alt={title} src={Icon}/> : <Icon/>}
    </div>
    {title && <Typography component="h6" variant="h6">{title}</Typography>}
    {subtitle && <Typography variant="body1">{subtitle}</Typography>}
  </div>
));

bigStatement.propTypes = {
  Icon: oneOfType([object, string]),
  title: string,
  size: oneOf(["small", "medium", "large", "auto"]),
  subtitle: string,
  color: oneOf(["default", "inherit", "primary", "secondary"])
};

bigStatement.defaultProps = {
  Icon: DefaultIcon,
  size: "auto",
  color: "default",
};

export default bigStatement;
