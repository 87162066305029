import React from 'react';
import APISearch from "../APISearch";
import UserItem from "./UserItem";

const renderSearchItem = (user, onClick, selected) => (
  <UserItem key={user.email} onClick={onClick} user={user} selected={selected}/>
);

const SearchUser = ({selection, onChangeSelection, multiple}) => {
  return (
      <APISearch baseURL={"misc/users/"} optionsGetter={data => data.users} selection={selection}
                 multiple={multiple} onChangeSelection={onChangeSelection} itemRenderer={renderSearchItem}
                   inputLabel="User name" helperText="Type in a few letters to find a user"/>
  );
};

export default SearchUser;