import React from "react";
import {lighten} from "@material-ui/core/styles/colorManipulator";
import Toolbar from "@material-ui/core/Toolbar";
import classNames from "classnames";
import {any, arrayOf, func, string, bool} from "prop-types";
import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DuplicateIcon from '@material-ui/icons/FileCopy';
import {constant} from "lodash";
import SlideLeft from "../../Transitions/SlideLeft";

const styles = theme => ({
  root: {paddingRight: theme.spacing(1)},
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {flex: '1 1 100%'},
  actions: {whiteSpace: "nowrap", color: theme.palette.text.secondary},
  title: {flex: '0 0 auto'},
});

const tableToolbar = withStyles(styles)((
  {selected, title, onDeleteRows, onEditRow, onDuplicateRow, preventEdit, selectable, classes}
) => (
  <Toolbar className={classNames(classes.root, {[classes.highlight]: selected.length > 0})}>
    <div className={classes.title}>
      {selected.length > 0 ? (
        <Typography color="inherit" variant="subtitle1">{selected.length} selected</Typography>
      ) : (
        <Typography variant="h6" id="tableTitle">{title}</Typography>
      )}
    </div>
    <div className={classes.spacer}/>
    {selectable &&
    <div className={classes.actions}>
      <SlideLeft in={selected.length > 0} mountOnEnter unmountOnExit>
        <div>
          <Tooltip title="Duplicate">
            <span>
              <IconButton aria-label="Duplicate" onClick={onDuplicateRow}
                          disabled={selected.length > 1}>
                <DuplicateIcon/>
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Edit">
            <span>
              <IconButton aria-label="Edit" onClick={onEditRow}
                          disabled={preventEdit(selected)}>
                <EditIcon/>
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" onClick={onDeleteRows}>
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        </div>
      </SlideLeft>
    </div>}
  </Toolbar>
));

tableToolbar.propTypes = {
  title: string,
  selected: arrayOf(any).isRequired,
  onEditRow: func.isRequired,
  onDeleteRows: func.isRequired,
  preventEdit: func,
  selectable: bool.isRequired,
  onDuplicateRow: func.isRequired,
};

tableToolbar.defaultProps = {
  preventEdit: constant(false),
};

export default tableToolbar;