import {object, arrayOf, number, shape, string} from 'prop-types';
import attachment from "./attachment";

export default shape({
  id: number,
  title: string,
  content: string,
  status: string,
  schedule_time: object,
  link: string,
  attachments: arrayOf(attachment),
  recipients: arrayOf(string),
});
