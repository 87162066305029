import {withStyles} from "@material-ui/core";
import React from "react";
import SectionHolder from "../shared/SectionHolder/SectionHolder";
import jumboImage from "../../shared/images/bobcaat-standing.png";
import BigStatement from "../shared/BigStatement/BigStatement";

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(14),
  },
});


const dashboardSection = withStyles(styles)((
  {user, classes}
) => {
  return (
    <SectionHolder title="Dashboard">
      <div className={classes.root}>
        <BigStatement title={`Hello ${user.full_name()} !`}
                      subtitle="Nothing to see here..." Icon={jumboImage}
                      size="large"/>
      </div>
    </SectionHolder>
  );
});

dashboardSection.propTypes = {};

dashboardSection.defaultProps = {};


export default dashboardSection;
