import React from 'react';
import ListItem from "@material-ui/core/ListItem";
import {ListItemAvatar, ListItemText, withStyles} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import UserIcon from "@material-ui/icons/AccountCircle";
import {bool, func, object} from "prop-types";

const styles = theme => ({
  itemAvatar: {
    display: "flex",
    justifyContent: "center",
  },
  userIcon: {
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "50%",
    width: 40,
    height: 40,
    padding: theme.spacing(1),
  },
});

const userItem = withStyles(styles)(({user, selected, onClick, classes}) => (
  <ListItem onClick={() => onClick(user)} selected={selected} button>
    <ListItemAvatar className={classes.itemAvatar}>
      {user.avatar ? <Avatar src={user.avatar}/> : <UserIcon className={classes.userIcon}/>}
    </ListItemAvatar>
    <ListItemText primary={user.last_name} secondary={user.first_name}/>
  </ListItem>
));

userItem.propTypes = {
  onClick: func.isRequired,
  selected: bool,
  user: object,
};

export default userItem;