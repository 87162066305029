import React from 'react';
import {createMuiTheme} from '@material-ui/core/styles';
import {red} from '@material-ui/core/colors';
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import Page from './components/Page';
import CssBaseline from "@material-ui/core/CssBaseline";
import './shared/styles/raleway.css';
import './shared/styles/animations.css';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";
import {omit} from "lodash";
import {Route, Switch} from "react-router-dom";
import LoginPage from "./components/LoginPage";

const opacities = [...Array(101).keys()].map(n => n / 100);

const defaultTheme = createMuiTheme();


/**
 * The theme of the application.
 * It should contain all semantic colors and typography definitions for the
 * app.
 * @type {Theme} The theme template.
 */
const lightThemeOptions = {

  // Colors
  palette: {
    type: 'light',
    primary: {main: "#5D6C89"},
    secondary: {
      main: '#FEB06A',
      contrastText: defaultTheme.palette.common.white,
    },
    ternary: {main: "#36D6E7", light: "#5edeeb", dark: "#2595a1", contrastText: defaultTheme.palette.getContrastText("#36D6E7")},
    quaternary: {main: "#FBF6F3", light: "#fbf7f5", dark: "#afacaa",  contrastText: defaultTheme.palette.getContrastText("#FBF6F3")},
    error: red,
    background: {
      disabled: defaultTheme.palette.grey[200],
      default: "#FBF6F3",
    }
  },

  // Typography
  typography: {
    useNextVariants: true,
    fontFamily: '"Raleway", sans-serif',
  },

  // Custom
  shades: {
    darker: opacities.map(o => `rgba(0,0,0,${o})`),
    lighter: opacities.map(o => `rgba(255,255,255,${o})`),
  },

  animations: {
    floatY: "floatY ease-in-out infinite",
    spinCircle: "spin linear infinite",
    hueRotate: "hue-rotate linear infinite",
    grayscale: "grayscale ease alternate infinite",

  },

  overrides: {
    MuiButton: {
      // Name of the rule
      contained: {
        // Some CSS
        boxShadow: 'none',
        border: `solid 1px ${defaultTheme.palette.divider}`,
      },
    },
  },

  // Constant
  metrics: {
    collapsedDrawerWidth: 64,
    drawerWidth: 180,
    appBarHeight: 64,
    mobileMenuHeight: 56,
    mobileAppBarHeight: 56,
  },
};

const darkThemeOptions = {
  ...lightThemeOptions,
  palette: {
    ...omit(lightThemeOptions.palette, "background"),
    background: {
      disabled: defaultTheme.palette.grey[700],
    },
    type: "dark"
  }
};

const lightTheme = responsiveFontSizes(createMuiTheme(lightThemeOptions));
const darkTheme = responsiveFontSizes(createMuiTheme(darkThemeOptions));


const mapStateToProps = state => {
  return {
    themeName: state.profile.currentUser ?
      (state.profile.currentUser.preferences.theme || "light") :
      "light",
  };
};

const app = withRouter(connect(mapStateToProps, null)(({themeName}) => {

  return (
    <MuiThemeProvider theme={themeName === "light" ? lightTheme : darkTheme}>
      <CssBaseline/>
      <Switch>
        <Route path="/login" exact><LoginPage/></Route>
        <Route path="/"><Page/></Route>
      </Switch>
    </MuiThemeProvider>
  );
}));

export default app;
