import React, {useEffect, useState} from "react";
import Table from "./Table";
import {difference, head, intersection, noop} from "lodash";
import {any, arrayOf, bool, func, number, object, string} from "prop-types";
import shapes from "../../../shared/shapes";


const tableContainer = (
  {
    rows, columns, defaultSelected, defaultRowsPerPage, elevation, title, renderer, noRowContent,
    onDeleteRows, onEditRow, compact, selectable, onDuplicateRow, ...props
  }
) => {
  const [orderDir, setOrderDir] = useState('asc');
  const [orderCol, setOrderCol] = useState(columns[0].id);
  const [selected, setSelected] = useState(defaultSelected);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  useEffect(() => {setSelected(intersection(selected, rows))}, [rows]);

  const handleRequestSort = (e, col) => {
    setOrderDir(orderCol === col && orderDir === "desc" ? "asc" : "desc");
    setOrderCol(col);
  };

  const handleSelectAllClick = e => setSelected(e.target.checked ? rows : []);

  const handleClick = row => {
    setSelected(!selected.includes(row) ? [...selected, row] : difference(selected, [row]));
  };

  return (
    <Table rows={rows} orderDir={orderDir} orderCol={orderCol} selected={selected} title={title}
             rowsPerPage={rowsPerPage} page={page} columns={columns} elevation={elevation}
             onRowClick={handleClick}
             compact={compact}
             selectable={selectable}
             isSelected={row => selected.includes(row)}
             onRequestSort={handleRequestSort}
             onSelectAllClick={handleSelectAllClick}
             onChangePage={(e, page) => setPage(page)}
             onChangeRowsPerPage={e => setRowsPerPage(e.target.value)}
             renderer={renderer}
             noRowContent={noRowContent}
             onDeleteRows={() => onDeleteRows(selected)}
             onEditRow={() => onEditRow(head(selected))}
             onDuplicateRow={() => onDuplicateRow(head(selected))}
             {...props}/>
  )
};

tableContainer.propTypes = {
  title: string,
  rows: arrayOf(object).isRequired,
  columns: arrayOf(shapes.tableHeadColumn),
  defaultSelected: arrayOf(object),
  defaultRowsPerPage: number,
  renderer: func,
  noRowContent: any,
  onDeleteRows: func,
  onEditRow: func,
  onDuplicateRow: func,
  compact: bool,
  selectable: bool,
};

tableContainer.defaultProps = {
  defaultSelected: [],
  defaultRowsPerPage: 5,
  onDeleteRows: noop,
  onEditRow: noop,
  onDuplicateRow: noop,
  compact: false,
  selectable: true,
};

export default tableContainer;