import React, {useState} from 'react';
import LoginPage from './LoginPage';
import ajax from "../../shared/util/ajax";
import {checkEmail, checkPassword} from "../../shared/util/validation";
import {head, isEmpty} from "lodash";
import {closeError, setAjaxError} from "../../store/actions/global";
import {connect} from "react-redux";

const REDIRECT_TO_APP_BASE = `/?t=`;


const mapDispatchToProps = dispatch => ({
  onCloseError: () => dispatch(closeError()),
  onAjaxError: err => dispatch(setAjaxError(err)),
});

const mapStateToProps = state => {
  return {
    error: state.global.error,
  };
};

const loginPageContainer = connect(mapStateToProps, mapDispatchToProps)((
  {error, onCloseError, onAjaxError}
) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(undefined);
  const [emailError, setEmailError] = useState(undefined);

  const handleConfirm = () => {

    const emailErrors = checkEmail("email", email);
    const passwordErrors = checkPassword("password", password);

    setEmailError(head(emailErrors));
    setPasswordError(head(passwordErrors));

    if (isEmpty(emailErrors) && isEmpty(passwordErrors)) {
      ajax.post('/auth/admin/signin', {email, password})
        .then(r => window.location.href = REDIRECT_TO_APP_BASE + r.data.token)
        .catch(onAjaxError);
    }
  };

  return (
    <LoginPage
      email={email}
      password={password}
      onChangeEmail={e => setEmail(e.currentTarget.value)}
      onChangePassword={e => setPassword(e.currentTarget.value)}
      onConfirm={handleConfirm}
      error={error}
      onCloseError={onCloseError}
      emailError={emailError}
      onAjaxError={onAjaxError}
      passwordError={passwordError}/>
  );
});

export default loginPageContainer;