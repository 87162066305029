import React, {useRef, useState} from "react";
import NotificationCreator from "./NotificationCreator";
import {arrayOf, bool, func, string} from 'prop-types';
import {defer} from "lodash";
import {findURL} from "../../../../shared/util/patterns";
import {connect} from "react-redux";


const mapStateToProps = () => ({});

const notificationCreatorContainer = connect(mapStateToProps)((
  {
    content, onChangeContent, images, onPostNow, onSaveDraft, onSchedule, onAttachImage,
    onDetachImage, title, onChangeTitle, postingLocked, onReorderImages, recipients,
    onOpenRecipientsDialog, onDeleteRecipient, isBroadcast, onSetIsBroadcast
  }
) => {
  const [schedulePaneOpen, setSchedulePaneOpen] = useState(false);
  const [isEmojiKeyboardOpen, setEmojiKeyboardOpen] = useState(false);
  const messageBoxRef = useRef();


  const handleSelectEmoji = emoji => {
    const caret = messageBoxRef.current.selectionStart;
    onChangeContent(content.slice(0, caret) + emoji + content.slice(caret));
    defer(() => messageBoxRef.current.selectionStart = caret + emoji.length);
  };

  const link = findURL(content);

  return (
    <NotificationCreator content={content}
                         postingLocked={postingLocked}
                         isEmojiKeyboardOpen={isEmojiKeyboardOpen}
                         onOpenEmojiKeyboard={() => setEmojiKeyboardOpen(true)}
                         onCloseEmojiKeyboard={() => setEmojiKeyboardOpen(false)}
                         onSelectEmoji={handleSelectEmoji}
                         messageBoxRef={messageBoxRef}
                         onChangeContent={e => onChangeContent(e.target.value)}
                         onRequestSchedule={() => setSchedulePaneOpen(true)}
                         schedulePaneOpen={schedulePaneOpen}
                         onCancelSchedule={() => setSchedulePaneOpen(false)}
                         onAttachImage={onAttachImage}
                         onPostNow={onPostNow}
                         onSaveDraft={onSaveDraft}
                         onSchedule={onSchedule}
                         onDetachImage={onDetachImage}
                         images={images}
                         link={link}
                         title={title}
                         onChangeTitle={onChangeTitle}
                         onReorderImages={onReorderImages}
                         recipients={recipients}
                         onOpenRecipientsDialog={onOpenRecipientsDialog}
                         onDeleteRecipient={onDeleteRecipient}
                         onSetIsBroadcast={onSetIsBroadcast}
                         isBroadcast={isBroadcast}/>
  );
});

notificationCreatorContainer.propTypes = {
  content: string.isRequired,
  onChangeContent: func.isRequired,
  images: arrayOf(string).isRequired,
  onAttachImage: func.isRequired,
  onDetachImage: func.isRequired,
  onPostNow: func.isRequired,
  onSaveDraft: func.isRequired,
  onSchedule: func.isRequired,
  title: string,
  onChangeTitle: func.isRequired,
  postingLocked: bool,
  onReorderImages: func.isRequired,
  recipients: arrayOf(string),
  onOpenRecipientsDialog: func.isRequired,
  onDeleteRecipient: func.isRequired,
  isBroadcast: bool,
  onSetIsBroadcast: func.isRequired,
};

export default notificationCreatorContainer;