import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import {Typography} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import LockIcon from '@material-ui/icons/Lock';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {func, object, string} from "prop-types";
import logo from "../../shared/images/logo-trans-512.png";
import logo2 from "../../shared/images/logo-trans-512-white.png";
import classNames from "classnames";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import {isWidthDown} from "../shared/mui";
import Snackbar from "../shared/Snackbar";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

const styles = theme => ({
  root: {
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.quaternary.main,
    paddingBottom: theme.spacing(4),
  },
  logoContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: 60,
    marginRight: theme.spacing(1),
    verticalAlign: "middle",
  },
  paper: {
    marginBottom: theme.spacing(4),
    textAlign: "center",
    border: `solid 1px ${theme.palette.divider}`,
    maxWidth: 800,
  },

  main: {
    padding: theme.spacing(4),
  },
  header: {
    marginBottom: theme.spacing(4),
  },
  body: {
    padding: theme.spacing(4),
  },
  buttons: {
    textAlign: "right",
    paddingRight: theme.spacing(4),
  },
  extras: {},
  signUpLink: {
    marginLeft: theme.spacing(0),
  },
  splash: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  logo2: {
    width: "100px",
  },
});

const LoginPage = withStyles(styles)((
  {
    email, password, emailError, passwordError, onChangeEmail, onChangePassword, onConfirm,
    onCloseError, error, classes
  }
) => {
  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      onConfirm();
    }
  };

  const isMobile = isWidthDown("xs");

  return (
    <div className={classes.root}>
      <Snackbar message={error.message}
                isOpen={error.show}
                onClose={onCloseError} variant="error"/>
      <div className={classes.logoContainer}>
        <img src={logo} className={classNames(classes.logo)} alt="Logo"/>
        <Typography component="h1" variant="h4" color="primary">bobcaat admin</Typography>
      </div>
      <Paper className={classes.paper} elevation={0} square={isMobile}>
        <Grid container>
          <Grid item xs={12} sm={7}>
            <div className={classes.main}>
            <div className={classes.header}>
              <Typography component="div" align="center">
                <LockIcon color="secondary" fontSize="large"/>
              </Typography>
              <Typography component="h3" variant="h6" align="center" paragraph>
                Sign in
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                Please enter your email address and your password in order to connect
                to the application.
              </Typography>
            </div>
            <Divider variant="middle"/>
            <div className={classes.body}>
              <TextField autoFocus margin="dense" name="email" label="email"
                         type="email" fullWidth value={email} onChange={onChangeEmail}
                         onKeyDown={handleKeyDown} required
                         helperText={emailError || ""} error={Boolean(emailError)}/>
              <TextField margin="dense" name="password" label="password"
                         type="password" fullWidth value={password}
                         helperText={passwordError || ""} required
                         error={Boolean(passwordError)} onChange={onChangePassword}
                         onKeyDown={handleKeyDown}/>
            </div>
            <div className={classes.buttons}>
              <Button color="primary" variant="contained" onClick={onConfirm}>Login</Button>
            </div>
            </div>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={1} sm={5}>
              <div className={classes.splash}>
                <img src={logo2} className={classes.logo2} alt="Logo"/>
                <Typography variant="h4" align="center">bobcaat</Typography>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </Paper>
      <div className={classes.extras}>
        No account yet? <Link href={process.env.REACT_APP_LANDING_URL + "?action=signup"}
                              className={classes.signUpLink}>Sign up here</Link>
      </div>
    </div>
  );
});

LoginPage.propTypes = {
  email: string,
  password: string,
  emailError: string,
  passwordError: string,
  onChangeEmail: func.isRequired,
  onChangePassword: func.isRequired,
  onConfirm: func.isRequired,
  error: object,
  onCloseError: func.isRequired,
};

export default LoginPage;
