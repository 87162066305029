import axios from 'axios';
import {noop} from "lodash";

// Create our custom instance to use throughout the app.
const instance = axios.create();

instance.defaults.baseURL = process.env.REACT_APP_API_URL;
instance.defaults.headers.post['Content-Type'] = 'application/json';

// Always include the token in storage if any.
instance.interceptors.request.use(
  config => {
    config.headers['Authorization'] = localStorage.appToken;
    return config;
  },
  error => Promise.reject(error)
);

// Always "unwrap" the data from the response object.
// We don't care about the response headers and status.
instance.interceptors.response.use(r => r.data);

export const uploadFile = (url, file, onProgress = noop) => {
  const data = new FormData();
  data.append('file', file, file.name);

  return instance.put(url, data, {
    headers: {
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    },
    timeout: 50000,
    onUploadProgress: onProgress,
  });
};

export default instance;