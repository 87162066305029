import React from 'react';
import {bool, object, string} from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import * as notificationStatus
  from "../../../../../shared/refer/notificationStatus";
import PublishedIcon from "@material-ui/icons/Done";
import ScheduledIcon from "@material-ui/icons/Schedule";
import DraftIcon from "@material-ui/icons/Inbox";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    textAlign: "center",
  },
  timezone: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: 150,
    overflowX: "hidden",
    display: 'block',
    margin: "auto",
  },
  button: {
    marginTop: theme.spacing(1),
  }
});

const ICONS = {
  [notificationStatus.DRAFT.slug]: <DraftIcon fontSize="large"
                                              style={{color: "orange"}}
                                              titleAccess="Draft"/>,
  [notificationStatus.SCHEDULED.slug]: <ScheduledIcon fontSize="large"
                                                      style={{color: "cornflowerblue"}}
                                                      titleAccess="Scheduled"/>,
  [notificationStatus.PUBLISHED.slug]: <PublishedIcon fontSize="large"
                                                      style={{color: "green"}}
                                                      titleAccess="Published"/>,
};

const getIcon = (scheduleTime, status) => ICONS[status];

const statusCell = withStyles(styles)((
  {status, scheduleTime, compact, classes}
) => (
  <div className={classes.root}>
    {!compact && getIcon(scheduleTime, status)}
    {scheduleTime &&
    <div>
      <Typography variant="subtitle2">
        {scheduleTime.format("L")}
      </Typography>
      <Typography variant="subtitle2">
        {scheduleTime.format("LTS")}
      </Typography>
      <Typography variant="caption" className={classes.timezone}
                  color="textSecondary"
                  title={scheduleTime.format("z")}>
        {scheduleTime.format("z")}
      </Typography>
    </div>}
  </div>
));

statusCell.propTypes = {
  status: string,
  scheduleTime: object,
  compact: bool,
};

export default statusCell;