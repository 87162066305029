import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {array, bool, func} from "prop-types";
import {flow, isEmpty} from "lodash";
import SearchUser from "../../../shared/SearchUser";

const searchUserDialog = (
  {open, selection, onConfirm, onClose, onChangeUser}
) => {

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle id="confirm-dialog-title">Select one or more recipients</DialogTitle>
      <DialogContent>
        <SearchUser selection={selection} onChangeSelection={onChangeUser} multiple/>
      </DialogContent>
      <DialogActions>
        <Button onClick={flow(onConfirm, onClose)} disabled={isEmpty(selection)} color="primary">OK</Button>
      </DialogActions>
    </Dialog>
  );
};

searchUserDialog.propTypes = {
  open: bool,
  selection: array,
  onConfirm: func.isRequired,
  onClose: func,
  onChangeUser: func.isRequired,
};

export default searchUserDialog;
