import React from "react";
import * as PropTypes from 'prop-types';
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import {amber, blue, green} from "@material-ui/core/colors";
import {SnackbarContent, withStyles} from "@material-ui/core";
import classNames from 'classnames';


const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: blue[600],
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const snackbar = withStyles(styles)(
  ({message, isOpen, variant, onClose, classes}) => {
    const Icon = variantIcon[variant];
    return (
      <Snackbar
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        open={isOpen} autoHideDuration={4000}
        ContentProps={{'aria-describedby': 'message-id'}} onClose={onClose}
      >
        <SnackbarContent
          className={classes[variant]}
          message={
            <span id="message-id" className={classes.message}>
           <Icon className={classNames(classes.icon, classes.iconVariant)}/>
              {message}
        </span>
          }
          action={[
            <IconButton
              key="close" aria-label="Close" color="inherit"
              className={classes.icon} onClick={onClose}
            >
              <CloseIcon/>
            </IconButton>
          ]}
        />
      </Snackbar>
    )
  });


snackbar.propTypes = {
  message: PropTypes.string,
  variant: PropTypes.oneOf(["info", "warning", "error", "success"]),
};

snackbar.defaultProps = {
  message: "",
  variant: "info"
};

export default snackbar;
