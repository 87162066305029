import * as actionTypes from "../actions/actionTypes";
import {makeReducer, withSpreadState} from "./common";

const initialState = {
  error: {},
  info: {},
  warning: {},
  success: {},
  filters: {
    notificationStatus: null,
  },
  bigStatus: {
    status: "success",
    title: "",
    message: "",
    handled: true,
  }
};

const setError = action => ({
  error: {
    ...action.val,
    show: true,
  }
});
const setInfo = action => ({
  info: {
    msg: action.val,
    show: true,
  }
});
const setWarning = action => ({
  warning: {
    msg: action.val,
    show: true,
  }
});
const setSuccess = action => ({
  success: {
    msg: action.val,
    show: true,
  }
});

const closeError = (_, state) => ({
  error: {
    ...state.error,
    show: false,
  }
});
const closeInfo = (_, state) => ({
  info: {
    ...state.info,
    show: false,
  }
});
const closeWarning = (_, state) => ({
  warning: {
    ...state.warning,
    show: false,
  }
});
const closeSuccess = (_, state) => ({
  success: {
    ...state.success,
    show: false,
  }
});

const setFilterNotificationStatus = (action, state) => ({
  filters: {
    ...state.filters,
    notificationStatus: action.val,
  }
});

const setBigStatus = action => ({
  bigStatus: {
    ...action.val,
    handled: false,
  }
});

const closeBigStatus = (action, oldState) => ({
  bigStatus: {
    ...oldState.bigStatus,
    handled: true,
  }
});


export default withSpreadState(initialState)(makeReducer({
  [actionTypes.GLOBAL_SET_ERROR]: setError,
  [actionTypes.GLOBAL_SET_INFO]: setInfo,
  [actionTypes.GLOBAL_SET_SUCCESS]: setSuccess,
  [actionTypes.GLOBAL_SET_WARNING]: setWarning,
  [actionTypes.GLOBAL_CLOSE_ERROR]: closeError,
  [actionTypes.GLOBAL_CLOSE_INFO]: closeInfo,
  [actionTypes.GLOBAL_CLOSE_SUCCESS]: closeSuccess,
  [actionTypes.GLOBAL_CLOSE_WARNING]: closeWarning,

  [actionTypes.GLOBAL_SET_FILTER_NOTIFICATION_STATUS]: setFilterNotificationStatus,
  [actionTypes.GLOBAL_CLOSE_BIG_STATUS] : closeBigStatus,
  [actionTypes.GLOBAL_SET_BIG_STATUS] : setBigStatus,
}));