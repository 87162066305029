import React, {useRef, useState} from "react";
import {arrayOf, bool, element, func, number, string} from "prop-types";
import {getDataURL, MBYTE} from "../../../shared/util/files";
import {compact, head, noop} from "lodash";
import CropImageDialog from "../../shared/CropImageDialog";
import {setStringError} from "../../../store/actions/global";
import {connect} from "react-redux";

const mapDispatchToProps = dispatch => {
  return {
    onError: msg => dispatch(setStringError(msg)),
  }
};

const MAX_SIZE = 10 * MBYTE;
const FILE_TYPE = 'image/';

const imagePicker = connect(null, mapDispatchToProps)((
  {enabled, onError, onUpload, nocrop, multiple, children, maxSize, accept}
) => {

  const inputRef = useRef();
  const [tempImage, setTempImage] = useState(null);

  const handleAvatarClick = () => {
    inputRef.current.value = "";
    inputRef.current.click();
  };

  const getFileContent = getDataURL(maxSize || MAX_SIZE, FILE_TYPE, onError);


  const handleInputChange = async e => {
    if (e.currentTarget.files.length === 1) {
      const result = await getFileContent(head(e.currentTarget.files));
      if (result) {
        nocrop ? onUpload([result]) : setTempImage(result);
      }
    } else {
      Promise.all([...e.currentTarget.files].map(getFileContent))
        .then(contents => onUpload(compact(contents)));
    }
  };

  const handleUpload = image => {
    onUpload([image]);
    setTempImage(null);
  };

  return (
    enabled ?
      <>
        {!nocrop && <CropImageDialog
          isOpen={Boolean(tempImage)}
          srcImage={tempImage}
          onCancel={() => setTempImage(null)}
          onSubmit={handleUpload}/>}
        <input type="file" ref={inputRef} name="avatar" multiple={multiple}
               style={{display: "none"}} onChange={handleInputChange}
               accept={accept ? accept.join(', ') : "image/png, image/jpeg, image/gif"}/>
        {React.cloneElement(children, {onClick: handleAvatarClick})}
      </> :
      children
  );
});

imagePicker.propTypes = {
  enabled: bool,
  onUpload: func,
  children: element.isRequired,
  nocrop: bool,
  multiple: bool,
  maxSize: number,
  accept: arrayOf(string),
};

imagePicker.defaultProps = {
  enabled: true,
  nocrop: false,
  onUpload: noop,
};

export default imagePicker;