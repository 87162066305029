import React, {useEffect, useState} from 'react';
import App from "./Page";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {
  closeBigStatus,
  closeError,
  closeInfo,
  closeSuccess,
  closeWarning,
  setInfo,
} from "../../store/actions/global";
import DisconnectedDialog from "./DisconnectedDialog";
import {loadAllNotificationsAsync} from "../../store/actions/notification";
import Splash from "./Splash";
import ajax from "../../shared/util/ajax";
import {noop} from "lodash";
import {loadUserAsync} from "../../store/actions/profile";


const mapDispatchToProps = dispatch => ({
  onLoadProfileAsync: () => dispatch(loadUserAsync()),
  onLoadAllNotificationsAsync: () => dispatch(loadAllNotificationsAsync()),
  onUserChanged: (userName) => dispatch(setInfo(`Nice to see you again, ${userName}!`)),
  onCloseError: () => dispatch(closeError()),
  onCloseInfo: () => dispatch(closeInfo()),
  onCloseWarning: () => dispatch(closeWarning()),
  onCloseSuccess: () => dispatch(closeSuccess()),
  onCloseBigStatus: () => dispatch(closeBigStatus()),
});

const mapStateToProps = state => {
  return {
    error: state.global.error,
    info: state.global.info,
    success: state.global.success,
    warning: state.global.warning,
    user: state.profile.currentUser,
    bigStatus: state.global.bigStatus,
  };
};


const renderDisconnect = error => {
  if (error.show && error.code === 401)
    return <DisconnectedDialog error="auth"/>;
};

const handleRenewToken = () => {
  ajax.get("auth/token")
    .then(data => {localStorage.appToken = data.data.token})
    .catch(noop)
};


const appContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)((
  {
    onLoadProfileAsync, onUserChanged,
    onSetInfo, onLoadAllNotificationsAsync, user, error, bigStatus, onCloseBigStatus, ...props
  }) => {

  const [isDrawerOpen, setDrawerOpen] = useState(true);
  const [welcomeShown, setWelcomeShown] = useState(false);

  useEffect(() => {

    // Extract token if provided.
    const query = new URLSearchParams(props.location.search);
    if (query.get('t')) {
      localStorage.appToken = query.get('t');
      window.history.pushState("", "", '/');
    }
    ajax.get("auth/token/status")
      .then(data => {
        if (data.data.status === "KO") {
          window.location.href = "/login";
        }
        onLoadProfileAsync();
        onLoadAllNotificationsAsync();
        setInterval(handleRenewToken, 1800 * 1000);
      });
  }, []);


  useEffect(() => {
    if (user && !welcomeShown) {
      setWelcomeShown(true);
      onUserChanged(user.first_name);
    }
  }, [user]);

  return (
    <>
      {renderDisconnect(error)}
      {!user && <Splash/>}
      {user &&
      <App
        {...props}
        onCloseBigStatus={onCloseBigStatus}
        bigStatus={bigStatus}
        error={error}
        isDrawerOpen={isDrawerOpen}
        onOpenDrawer={() => setDrawerOpen(true)}
        onCloseDrawer={() => setDrawerOpen(false)}/>}
    </>
  );
}));

export default appContainer;