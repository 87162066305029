import React, {useState} from 'react';
import NotificationsTable from './NotificationsTable';
import {connect} from "react-redux";
import {string} from "prop-types";
import {
  deleteNotificationAsync,
  duplicateNotificationAsync, setNotificationWizardState,
} from "../../../store/actions/notification";
import FilteredNotifications from "../hoc/FilteredNotifications";
import ConfirmDialog from "../../shared/ConfirmDialog";


const mapStateToProps = state => ({
  filterStatus: state.global.filters.notificationStatus,
});

const mapDispatchToProps = dispatch => ({
  onDeleteNotification: notification => dispatch(deleteNotificationAsync(notification.id)),
  onDuplicateNotification: notification => dispatch(duplicateNotificationAsync(notification.id)),
  onEditNotification: notification => dispatch(setNotificationWizardState(notification.id)),
});

const NotificationTableContainer = connect(mapStateToProps, mapDispatchToProps)((
  {
    search, onEditNotification, onDuplicateNotification, onDeleteNotification,
  }
) => {

  const [notificationsToDelete, setNotificationsToDelete] = useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleDeleteRow = () => {
    notificationsToDelete.forEach(onDeleteNotification);
    setNotificationsToDelete([]);
  };

  const handleRequestDeleteRows = notifications => {
    setNotificationsToDelete(notifications);
    setShowDeleteDialog(true);
  };

  return (
    <>
      {showDeleteDialog &&
      <ConfirmDialog
        title={`Delete these ${notificationsToDelete.length} row(s)?`}
        onClose={() => setShowDeleteDialog(false)}
        onCancel={() => setNotificationsToDelete([])}
        onConfirm={handleDeleteRow}>
        You will not be able to recover them anymore.
      </ConfirmDialog>}
      <FilteredNotifications search={search}>
        <NotificationsTable
          onEditRow={onEditNotification}
          onDuplicateRow={onDuplicateNotification}
          onDeleteRows={handleRequestDeleteRows}/>
      </FilteredNotifications>
    </>
  );

});
NotificationTableContainer.propTypes = {
  search: string,
};


export default NotificationTableContainer;