import React, {useState} from 'react';
import ScheduleDialog from './ScheduleDialog';
import {bool, func} from 'prop-types';
import {connect} from "react-redux";
import moment from "moment";

const mapStateToProps = state => ({
  user: state.profile.currentUser,
});

const scheduleDialogContainer = connect(mapStateToProps)((
  {open, onSubmit, onCancel, user}
) => {

  const [selectedDate, setSelectedDate] = useState(moment().minutes(0).seconds(0).milliseconds(0).add(1, "h"));

  const handleSubmit = () => {
    return onSubmit(selectedDate, user.timezone);
  };

  const handleChangeDate = d => {
    setSelectedDate(moment(d).seconds(0).milliseconds(0))
  };

  return (
    <ScheduleDialog
      open={open}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      selectedDate={selectedDate}
      onDateChange={handleChangeDate}/>
  );
});

scheduleDialogContainer.propTypes = {
  open: bool.isRequired,
  onSubmit: func.isRequired,
  onCancel: func.isRequired,
};

export default scheduleDialogContainer;